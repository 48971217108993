import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryFunctionContext,
} from '@tanstack/react-query';
import {
  createCustomItemProperties,
  getPriceListManagerData,
  getCustomItemProperties,
  getGetGlobalVariables,
  createGlobalVariables,
  updateGlobalVariables,
  deleteGlobalVariables,
  getCustomFormulas,
  addPriceCalculation,
  updatePriceCalculation,
  upsertItemException,
  getProductPriceRecordsDto,
  getProductPriceRecord,
  getItemsGroups,
  getItemsNames,
  getFilteredItemPricing,
  getFilteredGroupPricing,
  changeProductItemPrice,
  changePricesByOverrideDetails,
  deleteItemException,
  applyRounding,
  postAnchor,
  serverSideManageRounding,
  getSyncProductCount,
  syncThirdPartySystem,

} from './pricing.endpoints';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useToast } from '@chakra-ui/toast';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  FilteredGroupPricing,
  ItemsNames,
  PriceCalculationType,
  ProductAnchorRequest,
  ProductModelType,
  ServerSideManageRounding,
  ServerSideModel,
} from './pricing.models';
import { useGetMainBranch } from '../LMBranches/useLmBranches.hooks';
import { AxiosError } from 'axios';

export const baseKey = process.env.REACT_APP_URL;

export const useCreateCustomItemProperties = () => {
  const client = useQueryClient();
  const params = useParams();
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen: isManageColumnOpen, onToggle: onToggleManageColumn } =
    useDisclosure();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [createCustomItemProperties.name],
    mutationFn: (variables: {
      segmentId: string;
      itemPropertyColumnNames: string[] | undefined;
      accessToken: string | undefined;
    }) => {
      return createCustomItemProperties.query(
        variables.segmentId,
        variables.itemPropertyColumnNames,
        variables.accessToken
      );
    },
    onSuccess: () => {
      const invalidKey = [
        `${baseKey}.${getPriceListManagerData.name}`,
        params.segmentId,
        params.detailsId,
        user?.accessToken,
      ];
      const getCustomProperties = [
        `${baseKey}.${getCustomItemProperties.name}`,
        params.segmentId,
        user?.accessToken,
      ];
      toast({
        description: 'Columns added successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
      onToggleManageColumn();
      setTimeout(() => {
        void client.refetchQueries({ queryKey: invalidKey });
        void client.refetchQueries({ queryKey: getCustomProperties });
      }, 300);
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    updatingCreateCustomItemProperties: isPending,
    updateCreateCustomItemPropertiesAsync: mutateAsync,
    isUpdatingError: isError,
    isUpdatingCreateCustomItemPropertiesSuccess: isSuccess,
    isManageColumnOpen,
    onToggleManageColumn,
  };
};

export const useGetProductPriceRecord = (
  segmentId: string | undefined,
  itemId: string | undefined,
  accessToken: string | undefined
) => {
  const queryFn = ({
    queryKey,
    meta,
    signal,
  }: QueryFunctionContext<
    [string, string | undefined, string | undefined]
  >) => {
    return getProductPriceRecord.query(
      { queryKey: queryKey, signal: signal, meta: meta },
      accessToken
    );
  };

  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getProductPriceRecord.name}`, segmentId, itemId],
    queryFn, // Use the adjusted queryFn
    enabled: !!segmentId && !!itemId && !!accessToken, // Ensure the query runs only if both segmentId and accessToken are available
    staleTime: Infinity,
  });

  return { isLoading, isError, isFetching, data, refetch };
};

export const useGetProductPriceRecordsDto = (
  segmentId: string | undefined,
  accessToken: string | undefined
) => {
  const queryFn = ({
    queryKey,
    meta,
    signal,
  }: QueryFunctionContext<[string, string | undefined]>) => {
    return getProductPriceRecordsDto.query(
      { queryKey: queryKey, signal: signal, meta: meta },
      accessToken
    );
  };

  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getProductPriceRecordsDto.name}`, segmentId],
    queryFn, // Use the adjusted queryFn
    enabled: !!segmentId && !!accessToken, // Ensure the query runs only if both segmentId and accessToken are available
    staleTime: Infinity,
  });

  return { isLoading, isError, isFetching, data, refetch };
};

export const useGetPriceListManagerData = (
  segmentId: string | undefined,
  detailsId: string | undefined,
  accessToken: string | undefined
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [
      `${baseKey}.${getPriceListManagerData.name}`,
      segmentId,
      detailsId,
      accessToken,
    ],
    queryFn: getPriceListManagerData.query,
    enabled: !!accessToken,
    staleTime: Infinity,
  });

  return {
    getPriceListManagerDataLoading: isLoading,
    isGetPriceListManagerDataFetching: isFetching,
    refetchGetPriceListManagerData: refetch,
    isError,
    priceListManagerData: data,
  };
};

export const useGetCustomItemProperties = (
  segmentId: string | undefined,
  accessToken: string | undefined
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [
      `${baseKey}.${getCustomItemProperties.name}`,
      segmentId,
      accessToken,
    ],
    queryFn: getCustomItemProperties.query,
    enabled: !!accessToken && !!segmentId,
    staleTime: Infinity,
  });

  return {
    getCustomItemPropertiesLoading: isLoading,
    isGetCustomItemPropertiesFetching: isFetching,
    refetchCustomItemProperties: refetch,
    isError,
    customItemProperties: data,
  };
};

export const useGetGlobalVariables = (
  accessToken: string | undefined,
  branchId: string | undefined
) => {
  const { isLoading, isError, isFetching, data } = useQuery({
    queryKey: [
      `${baseKey}.${getGetGlobalVariables.name}`,
      accessToken ?? '',
      branchId,
    ],
    queryFn: getGetGlobalVariables.query,
    enabled: !!accessToken && !!branchId,
    staleTime: Infinity,
  });

  return {
    globalVariablesLoading: isLoading,
    globalVariablesFetching: isFetching,
    isError,
    globalVariables: data,
  };
};

export const useCreateGlobalVariables = () => {
  const client = useQueryClient();

  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [createGlobalVariables.name],
    mutationFn: (variables: {
      branchId: number;
      variableName: string | undefined;
      variableValue: number;
      accessToken: string | undefined;
    }) => {
      return createGlobalVariables.query(
        variables.branchId,
        variables.variableName,
        variables.variableValue,
        variables.accessToken
      );
    },
    onSuccess: (res, variables) => {
      const invalidKey = [
        `${baseKey}.${getGetGlobalVariables.name}`,
        variables?.accessToken,
        variables?.branchId.toString(),
      ];

      client.invalidateQueries({ queryKey: invalidKey });

      toast({
        description: 'Created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    createGlobalVariableLoading: isPending,
    createGlobalVariableAsync: mutateAsync,
    isCreatingGlobalVariableError: isError,
    isCreatingGlobalVariableSuccess: isSuccess,
  };
};

export const useUpdateGlobalVariables = () => {
  const client = useQueryClient();
  const toast = useToast();
  const { mainBranchId } = useGetMainBranch();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [updateGlobalVariables.name],
    mutationFn: (variables: {
      branchId: string | undefined;
      variableName: string | undefined;
      variableValue: number;
      accessToken: string | undefined;
      id: number;
    }) => {
      return updateGlobalVariables.query(
        variables.branchId,
        variables.variableName,
        variables.variableValue,
        variables.accessToken,
        variables.id
      );
    },
    onSuccess: (res, variables) => {
      const invalidKey = [
        `${baseKey}.${getGetGlobalVariables.name}`,
        variables?.accessToken,
        variables?.branchId?.toString() ?? mainBranchId?.id.toString(),
      ];

      client.invalidateQueries({ queryKey: invalidKey });

      toast({
        description: 'Updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    updateGlobalVariableLoading: isPending,
    updateGlobalVariableAsync: mutateAsync,
    isUpdatingGlobalVariableError: isError,
    isUpdatingGlobalVariableSuccess: isSuccess,
  };
};

export const useDeleteGlobalVariables = () => {
  const client = useQueryClient();

  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [deleteGlobalVariables.name],
    mutationFn: (variables: {
      accessToken: string | undefined;
      id: number;
      branchId: string | undefined;
    }) => {
      return deleteGlobalVariables.query(variables.accessToken, variables.id);
    },
    onSuccess: (res, variables) => {
      const invalidKey = [
        `${baseKey}.${getGetGlobalVariables.name}`,
        variables?.accessToken,
        variables?.branchId?.toString(),
      ];

      void client.invalidateQueries({ queryKey: invalidKey });

      toast({
        description: 'Deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    deleteGlobalVariableLoading: isPending,
    deleteGlobalVariableAsync: mutateAsync,
    isDeletingGlobalVariableError: isError,
    isDeletingGlobalVariableSuccess: isSuccess,
  };
};

export const useGetCustomFormulas = (
  branchId: string | undefined,
  accessToken: string | undefined
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [
      `${baseKey}.${getCustomFormulas.name}`,
      branchId,
      accessToken ?? '',
    ],
    queryFn: getCustomFormulas.query,
    enabled: !!accessToken && !!branchId,
    staleTime: Infinity,
  });

  return {
    customFormulasLoading: isLoading,
    customFormulasFetching: isFetching,
    customFormulasRefetch: refetch,
    isError,
    customFormulas: data,
  };
};

export const useAddPriceCalculation = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [addPriceCalculation.name],
    mutationFn: (variables: {
      accessToken: string | undefined;
      itemsToAdd: PriceCalculationType[];
    }) => {
      return addPriceCalculation.query(
        variables.accessToken,
        variables.itemsToAdd
      );
    },
    onSuccess: res => {
      toast({
        description: 'Added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
      return res;
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    addPriceCalculationLoading: isPending,
    addPriceCalculationAsync: mutateAsync,
    addPriceCalculationError: isError,
    addPriceCalculationSuccess: isSuccess,
  };
};

export const useUpdatePriceCalculation = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [updatePriceCalculation.name],
    mutationFn: (variables: {
      accessToken: string | undefined;
      itemsToUpdate: PriceCalculationType[];
    }) => {
      return updatePriceCalculation.query(
        variables.accessToken,
        variables.itemsToUpdate
      );
    },
    onSuccess: () => {
      toast({
        description: 'Updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    updatePriceCalculationLoading: isPending,
    updatePriceCalculationAsync: mutateAsync,
    updatePriceCalculationError: isError,
    updatePriceCalculationSuccess: isSuccess,
  };
};

export const useUpsertItemException = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [upsertItemException.name],
    mutationFn: (variables: {
      accessToken: string | undefined;
      exception: PriceCalculationType;
    }) => {
      return upsertItemException.query(
        variables.accessToken,
        variables.exception
      );
    },
    onSuccess: res => {
      return res;
    },
    onError: err => {
      toast({
        title: 'An unexpected error occurred. Please try again.',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    upsertItemExceptionLoading: isPending,
    upsertItemExceptionAsync: mutateAsync,
    upsertItemExceptionError: isError,
    upsertItemExceptionSuccess: isSuccess,
  };
};

export const useGetItemsGroups = (
  accessToken: string | undefined,
  branchId: string | undefined
) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: [`${baseKey}.${getItemsGroups.name}`, accessToken, branchId],
    queryFn: getItemsGroups.query,
    enabled: !!accessToken && !!branchId,
    staleTime: Infinity,
  });

  return {
    ItemsGroupsLoading: isLoading,
    isError,
    ItemsGroups: data,
  };
};

export const useGetItemsNames = (
  accessToken: string | undefined,
  branchId: string | undefined
) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: [`${baseKey}.${getItemsNames.name}`, accessToken, branchId],
    queryFn: getItemsNames.query,
    enabled: !!accessToken && !!branchId,
    staleTime: Infinity,
  });

  return {
    ItemsNamesLoading: isLoading,
    isError,
    ItemsNames: data as unknown as ItemsNames[],
  };
};

export const useGetFilteredItemPricing = (
  accessToken: string | undefined,
  branchId: string | undefined,
  items: string[]
) => {
  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: [
      `${baseKey}.${getFilteredItemPricing.name}`,
      accessToken,
      branchId,
      JSON.stringify(items),
    ],
    queryFn: () => getFilteredItemPricing.query(accessToken, branchId, items),
    enabled: !!accessToken && items?.length > 0 && !!branchId,
  });

  return {
    FilteredItemPricingLoading: isLoading,
    FilteredItemPricingRefetch: refetch,
    isError,
    FilteredItemPricing: data as unknown as ProductModelType,
  };
};

export const useGetFilteredGroupPricing = (
  accessToken: string | undefined,
  branchId: string | undefined,
  groups: string[]
) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: [
      `${baseKey}.${getFilteredGroupPricing.name}`,
      accessToken,
      branchId,
      JSON.stringify(groups),
    ],
    queryFn: () => getFilteredGroupPricing.query(accessToken, branchId, groups),
    enabled: !!accessToken && groups?.length > 0 && !!branchId,
    staleTime: Infinity,
  });

  return {
    FilteredGroupPricingLoading: isLoading,
    isError,
    FilteredGroupPricing: data as FilteredGroupPricing,
  };
};

export const useChangeProductItemPrice = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [changeProductItemPrice.name],
    mutationFn: (variables: {
      payload: PriceCalculationType;
      accessToken: string | undefined;
    }) => {
      return changeProductItemPrice.query(
        variables.payload,
        variables.accessToken
      );
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    changeProductItemPriceLoading: isPending,
    changeProductItemPriceAsync: mutateAsync,
  };
};

export const useChangePricesByOverrideDetails = () => {
  const toast = useToast();
  const client = useQueryClient();
  const params = useParams();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [changePricesByOverrideDetails.name],
    mutationFn: ({
      payload,
      segmentId,
      accessToken,
    }: {
      payload: {
        priceCalculationModifier: number;
        priceCalculationType: string;
        itemIds: string[];
      };
      segmentId: number | undefined;
      accessToken: string | undefined;
    }) => {
      return changePricesByOverrideDetails.query(
        payload,
        segmentId,
        accessToken
      );
    },
    onSuccess: (res, variables) => {
      const invalidateQueryGroupPricing = [
        `${baseKey}.${getFilteredGroupPricing.name}`,
        variables.accessToken,
        params.branchId,
      ];
      const invalidateQueryItemPricing = [
        `${baseKey}.${getFilteredItemPricing.name}`,
        variables.accessToken,
        params.branchId,
      ];

      client.invalidateQueries({
        queryKey: invalidateQueryItemPricing,
      });

      client.invalidateQueries({
        queryKey: invalidateQueryGroupPricing,
      });

      toast({
        description: 'Request submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: err => {
      const error = err as AxiosError;

      toast({
        description:
        error.message ?? 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    changePricesByOverrideDetailsPending: isPending,
    changePricesByOverrideDetailsAsync: mutateAsync,
    changePricesByOverrideDetailsIsError: isError,
    changePricesByOverrideDetailsIsSuccess: isSuccess,
  };
};

export const useDeleteItemExceptions = () => {
  const toast = useToast();
  const client = useQueryClient();
  const params = useParams();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [deleteItemException.name],
    mutationFn: ({
      payload,
      segmentId,
      accessToken,
    }: {
      payload: {
        itemIds: string[];
      };
      segmentId: number | undefined;
      accessToken: string | undefined;
    }) => {
      return deleteItemException.query(payload, segmentId, accessToken);
    },
    onSuccess: (res, variables) => {
      const invalidateQueryGroupPricing = [
        `${baseKey}.${getFilteredGroupPricing.name}`,
        variables.accessToken,
        params.branchId,
      ];
      const invalidateQueryItemPricing = [
        `${baseKey}.${getFilteredItemPricing.name}`,
        variables.accessToken,
        params.branchId,
      ];

      client.invalidateQueries({
        queryKey: invalidateQueryItemPricing,
      });

      client.invalidateQueries({
        queryKey: invalidateQueryGroupPricing,
      });

      toast({
        description: 'Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: err => {
      const error = err as AxiosError;

      toast({
        description:
        error.message ?? 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    deleteItemExceptionsPending: isPending,
    deleteItemExceptionsAsync: mutateAsync,
    deleteItemExceptionsIsError: isError,
    deleteItemExceptionsIsSuccess: isSuccess,
  };
};

export const useApplyRounding = () => {
  const toast = useToast();
  const client = useQueryClient();
  const params = useParams();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [applyRounding.name],
    mutationFn: ({
      payload,
      segmentId,
      accessToken,
    }: {
      payload: {
        roundingType: string;
        roundingValue: number;
        itemIds: string[] | undefined;
      };
      segmentId: number | undefined;
      accessToken: string | undefined;
    }) => {
      return applyRounding.query(payload, segmentId, accessToken);
    },
    onSuccess: () => {
      const invalidateQueryItemPricing = [
        `${baseKey}.${getProductPriceRecordsDto.name}`,
        params.segmentId,
      ];

      client.invalidateQueries({
        queryKey: invalidateQueryItemPricing,
      });

      toast({
        description: 'Rounding applied successfully.',
      })
    },
  onError: () => {
    toast({
      description: 'An unexpected error occurred. Please try again.',

      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right',
    });
  },
});

return {
  applyRoundingPending: isPending,
  applyRoundingAsync: mutateAsync,
  applyRoundingIsError: isError,
  applyRoundingIsSuccess: isSuccess,
};
};

export const usePostAnchor = () => {
  const toast = useToast();
  
  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: [postAnchor.name],
    mutationFn: (variables: {
      productAnchorRequest: ProductAnchorRequest |  undefined;
      accessToken: string | undefined;
    }) => {
      return postAnchor.query(
        variables.productAnchorRequest,
        variables.accessToken
      );
    },
    onSuccess: () => {
      toast({
        description: 'Successfully created anchor.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  })
  return{
    PostAnchorIsPending: isPending,
    PostAnchorAsync: mutateAsync,
    PostAnchorIsError: isError,
  };
};


export const useServerSideManageRounding = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: [serverSideManageRounding.name],
    mutationFn: (variables: {
      roundingPayload: ServerSideManageRounding & ServerSideModel;
      accessToken: string | undefined;
    }) => {
      return serverSideManageRounding.query(
        variables.roundingPayload,
        variables.accessToken
      );
    },
    onSuccess: () => {
      toast({
        description: 'Successfully applied rounding.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  })
  return{
    ServerSideManageRoundingIsPending: isPending,
    ServerSideManageRoundingAsync: mutateAsync,
    ServerSideManageRoundingIsError: isError,
  };
};


export const useGetSyncProductCount = (accessToken: string | undefined) => {
  const { isLoading, isError,  data, refetch, isFetching  } = useQuery({
    queryKey: [`${baseKey}.${getSyncProductCount.name}`, accessToken ?? ''],
    queryFn: getSyncProductCount.query,
    enabled: !!accessToken,
    // refetchInterval: 6000
  });

  return {
    SyncProductCountLoading: isLoading,
    isError,
    SyncProductCount: data,
    SyncProductCountRefetch: refetch,
    SyncProductCountRefetching:isFetching
  };
};

export const useSyncThirdPartySystem = () => {
  const toast = useToast();

  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: [syncThirdPartySystem.name],
    mutationFn: (variables: {accessToken: string | undefined; draftIds: number[] | null; draftName: string | null; isCreateDraftRequest: boolean}
    ) => {
      return syncThirdPartySystem.query(
        variables.accessToken,
        variables.draftIds,
        variables.draftName,
        variables.isCreateDraftRequest
      );
    },
    onSuccess: () => {
      toast({
        description: 'Sync initializing...',
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  })
  return{
    SyncThirdPartySystemIsPending: isPending,
    SyncThirdPartySystemAsync: mutateAsync,
    SyncThirdPartySystemIsError: isError,
  };
};