import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverFooter,
  PopoverTrigger,
  VStack,
  Portal,
  HStack,
  Text,
  Badge,
  Flex,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiGitBranch, FiCheck } from 'react-icons/fi';
import { useListManager, Branch } from '../../../hooks/useListManager';
import { useCreateBranch, useGetAllBranches } from '../../../apis/LMBranches';
import useAuth from '../../../hooks/useAuth';
import { ClipLoader } from 'react-spinners';
import { PageHeading, SmallTitle, SubHeading } from '../../../components/misc/text/TextComponents'

const BranchSelectPopover = () => {
  const initialFocusRef = React.useRef();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [filteredBranches, setFilteredBranches] = React.useState<
    Branch[] | undefined
  >([]);
  const [searchString, setSearchString] = React.useState<string | undefined>();
  const listManagerContext = useListManager();
  const { createBranch } = useCreateBranch();
  const { user } = useAuth();
  const { refetchBranch } = useGetAllBranches(user?.accessToken ?? undefined);

  useEffect(() => {
    if (listManagerContext.activeBranches) {
      setFilteredBranches(listManagerContext.activeBranches);
    }
  }, [listManagerContext.activeBranches]);

  useEffect(() => {
    if (!searchString) {
      setFilteredBranches(listManagerContext.activeBranches);
    } else {
      const searchResults = listManagerContext.activeBranches?.filter(x =>
        x.branchName.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredBranches(searchResults);
    }
  }, [searchString, listManagerContext.activeBranches]);

  const renderCreateOption = () => {
    if (
      searchString &&
      !listManagerContext.branches?.some(
        x =>
          x.branchName.toLowerCase() === searchString.toLowerCase() &&
          x.isCompleted === false
      )
    ) {
      return (
        <BranchItem
          branch={{
            id: 0,
            branchName: searchString,
            branchDescription: '',
            isMain: false,
            isCompleted: false,
          }}
          searchString={searchString}
          onBranchCreate={branchName => {
            createBranch(branchName, {
              onSuccess: async res => {
                try {
                  const branchData = await refetchBranch();
                  if (branchData) {
                    listManagerContext.onCreateBranchClose();
                    listManagerContext.setCurrentBranch(res, branchData.data);
                  }
                } catch (error) {
                  console.error('Refetch error:', error);
                }
              },
            });
          }}
          onClose={onClose}
        />
      );
    } else {
      return <></>;
    }
  };
  return (
    <Popover
      placement={'bottom-start'}
      //@ts-ignore
      initialFocusRef={initialFocusRef}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button
          variant={'link'}
          //leftIcon={<FiGitBranch />}
          //rightIcon={<FiArrowRight />}
          colorScheme={'black'}
          onClick={onToggle}
        >
          {listManagerContext.branch?.isMain && (
          <SmallTitle>Current Pricing</SmallTitle>
          )}
          {!listManagerContext.branch?.isMain && (
          <SmallTitle>{listManagerContext.branch?.branchName}</SmallTitle>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader textAlign={'left'}>Switch drafts</PopoverHeader>
          <PopoverBody px={0}>
            <VStack>
              <Input
                //@ts-ignore
                ref={initialFocusRef}
                px={4}
                placeholder={'Find or create a draft'}
                onChange={e => setSearchString(e.target.value)}
              />
              <Box w={'full'}>
                {filteredBranches?.map((x, i) => {
                  return (
                    <BranchItem
                      key={i}
                      branch={x}
                      searchString={searchString}
                      onBranchSelect={id =>
                        listManagerContext.setCurrentBranch(id)
                      }
                      onClose={onClose}
                    />
                  );
                })}
                {renderCreateOption()}
              </Box>
            </VStack>
          </PopoverBody>
          <PopoverFooter>
            <Text as={Link} to={'/pricing/branches'}>
              View all drafts
            </Text>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const BranchItem = ({
  branch,
  searchString,
  onBranchSelect,
  onBranchCreate,
  onClose,
  creatingBranch,
}: {
  branch: Branch | null;
  searchString: string | undefined;
  onBranchSelect?: (branchId: number) => void;
  onBranchCreate?: (branchName: string) => void;
  onClose: () => void;
  creatingBranch?: boolean;
}) => {
  const listManagerContext = useListManager();

  if (!branch) {
    return <></>;
  }
  const textValue = onBranchCreate
    ? `Create draft: ${branch?.branchName}`
    : branch?.branchName;
  return (
    <VStack spacing={0}>
      <Flex
        w={'full'}
        h={10}
        alignItems={'center'}
        _hover={{
          background: 'gray.200',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (onBranchSelect) {
            onBranchSelect(branch.id);
          } else if (onBranchCreate) {
            onBranchCreate(branch.branchName);
          }
          onClose();
        }}
      >
        <HStack w={'full'} justifyContent={'space-between'} px={4}>
          <HStack justifyContent={'flex-start'}>
            {listManagerContext.branch?.id === branch?.id && (
              <FiCheck fontSize={18} />
            )}
            <HighlightText text={textValue} highlight={searchString} />
          </HStack>
          {branch?.isMain && <Badge>Default</Badge>}
          {creatingBranch && <ClipLoader size="20px" />}
        </HStack>
      </Flex>
      <Divider py={0} variant={'solid'} colorScheme={'gray'} />
    </VStack>
  );
};

const HighlightText = ({
  text,
  highlight,
}: {
  text: string;
  highlight: string | undefined;
}) => {
  // Check if there's a valid highlight term and it exists within the text
  if (highlight && text.toLowerCase().includes(highlight.toLowerCase())) {
    // Break the text into parts: before, match, and after
    const index = text.toLowerCase().indexOf(highlight.toLowerCase());
    const beforeText = text.substring(0, index);
    const matchText = text.substring(index, index + highlight?.length);
    const afterText = text.substring(index + highlight?.length);
    // Render text, with the matched part in bold
    return (
      <span>
        {beforeText}
        <strong>{matchText}</strong>
        {afterText}
      </span>
    );
  }
  // If no highlight term or it doesn't exist in the text, render just the text
  return <span>{text}</span>;
};

export default BranchSelectPopover;
