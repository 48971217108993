import { useColorModeValue, useToken } from '@chakra-ui/react';

const usePricesmithColorMode = () => {
  const pageBackgroundColor = useColorModeValue('white', 'gray.800');
  const tableRowColor = useColorModeValue('white', 'gray.800');
  const tableRowAlternateColor = useColorModeValue('gray.100', 'gray.700');
  const sectionBorderColor = useColorModeValue('gray.200', 'gray.700');
  const tableBorderColor = useColorModeValue('gray.200', 'whiteAlpha.300');
  const whiteOrUndefined = useColorModeValue('white', undefined);
  const grayOrWhiteAlphaBorderColor = useColorModeValue('gray.200', 'whiteAlpha.300');
  const basicTextColor = useColorModeValue('black', 'white');
  const secondaryBrand = useColorModeValue('brand.secondary.light', 'brand.secondary.dark');
  const accentBrand = useColorModeValue('brand.accent.light', 'brand.accent.dark');
  const [accentBrandLightToken, accentBrandDarkToken] = useToken('colors', ['brand.accent.light', 'brand.accent.dark']);
  const accentBrandToken = useColorModeValue(accentBrandLightToken, accentBrandDarkToken);
  const accentBrandInverseToken = useColorModeValue(accentBrandDarkToken, accentBrandLightToken);
  const tableCellLinkTextColor = useColorModeValue('teal', 'teal.300');
  const inputBg = useColorModeValue('gray.50', 'gray.800');
  const buttonColor = useColorModeValue('blue.600', 'blue.600');
  const linkTextColor = useColorModeValue('blue.600', 'blue.300');
  const joshsAwesomeGray = useColorModeValue('rgba(163, 163, 163, 0.5)', 'rgba(163, 163, 163, 0.5)');


  // Add more color mode values here as needed

  return {
    pageBackgroundColor,
    joshsAwesomeGray,
    tableRowColor,
    tableRowAlternateColor,
    tableBorderColor,
    whiteOrUndefined,
    grayOrWhiteAlphaBorderColor,
    basicTextColor,
    secondaryBrand,
    accentBrand,
    accentBrandToken,
    accentBrandInverseToken,
    tableCellLinkTextColor,
    inputBg,
    sectionBorderColor,
    linkTextColor,
    buttonColor
  };
};

export default usePricesmithColorMode;