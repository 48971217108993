import React, { Reducer } from 'react';
import { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification as fbSendEmailVerification,
  User,
  UserCredential
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, getDocs, addDoc, updateDoc, DocumentData } from 'firebase/firestore';
import { setUserId } from 'firebase/analytics';
import { analytics, auth, db, firebaseConfig } from '../firebase';

export type FireBaseRole = "user" | "admin" | "globaladmin" | "distribute" | "accountmanager" | "dashboard" | "listmanager";

export type FireBaseSetting = "accountmanager" | "pricesheets" | "listmanager";

export interface CustomUser {
  id: string | null;
  emailVerified: boolean,
  accessToken: string | null;
  email: string | null;
  photoURL: string | null;
  displayName: string | null;
  role: "admin" | "user";
  phoneNumber: string | null;
  country: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  zipCode: string | null;
  about: string | null;
  isPublic: boolean;
  roles: FireBaseRole[];
  isAdmin: boolean;
  tenant_id: string | null;
  has_all_branches: boolean;
  settings: { [setting in FireBaseSetting]: { enabled: boolean; has_distribution?: boolean; } }
  admin_email: string | null;
  tenant_ids?: string [] | null | undefined;
  tenantSelectInfo: TenantSelectInfo[];
}

interface UserWithAccessToken extends User {
  accessToken?: string;
}

interface UserStateReducerProperties {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isEmailVerified: boolean;
  user: UserWithAccessToken | null;
}

interface AuthContextProperties {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isEmailVerified: boolean;
  user: CustomUser | null;
  method: string;
  login: (email: string, password: string) => Promise<UserCredential>;
  register: (email: string, password: string, name: string) => Promise<void>;
  logout: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  sendPasswordReset: (email: string) => Promise<void>;
  sendEmailVerification: () => Promise<void>;
  updateTenantId?: null | undefined | ((tenantId:string) => Promise<void>);
}

export interface TenantSelectInfo {
  tenantId: string,
  tenantName: string
}

const ADMIN_EMAILS = ['brian@pricesmith.com'];
const firebaseApp = initializeApp(firebaseConfig);
const AUTH = getAuth(firebaseApp);
const DB = getFirestore(firebaseApp);

type UserStateReducerActions = { type: "INITIALIZE", payload: { isAuthenticated: boolean; user: UserWithAccessToken | null; } };
const reducer: Reducer<UserStateReducerProperties, UserStateReducerActions> = (state, action) => {
  if (action.type === 'INITIALIZE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isEmailVerified: !!user?.emailVerified,
      user
    };
  }

  return state;
};

const login = async (email: string, password: string) => {
  const userCred = await signInWithEmailAndPassword(AUTH, email, password);
  setUserId(analytics, userCred.user.uid);
  return userCred;
}

const register = async (email: string, password: string, name: string) => 
  createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
    const userRef = doc(collection(DB, 'users'), res.user?.uid);

    await setDoc(userRef, {
      uid: res.user?.uid,
      email,
      displayName: name,
      roles: ['user', 'accountmanager', 'distribute'],
      has_all_branches: false
    });

    const userCred = await login(email, password);
    await fbSendEmailVerification(userCred.user)
  })
  .catch(err => {
    console.error(err);
    alert(err.message);
  });

const logout = () => 
  signOut(AUTH).then(() => {
    setUserId(analytics, "")
    window.location.reload();
  });

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try{
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    setUserId(analytics, user.uid)
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if(docs.docs.length === 0){
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        roles: ['user', 'accountmanager', 'distribute'],
        has_all_branches: false
      });
    }
  } catch(err){
    console.error(err);
    alert((err as Error).message);
  }
}

const sendPasswordReset = async(email: string) => {
  try{
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  }catch (err) {
    console.error(err);
    alert((err as Error).message);
  }
}

const sendEmailVerification = (state?: UserStateReducerProperties) => async () => {
  if (state?.user) {
    try {
      await fbSendEmailVerification(state.user);
      alert("Verification email sent!");
    }catch (err) {
      console.error(err);
      alert((err as Error).message);
    }
  }
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isEmailVerified: false,
  user: null
};

const AuthContext = createContext<AuthContextProperties>({
  ...initialState,
  method: 'firebase',
  login,
  register,
  logout,
  signInWithGoogle,
  sendPasswordReset,
  sendEmailVerification: sendEmailVerification(undefined),
  updateTenantId: null
});

function AuthProvider({ children }: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [idToken, setIdToken] = useState<string|undefined|null>();

  const [profile, setProfile] = useState<DocumentData | null>(null);
  const [tenantProfile, setTenantProfile] = useState<DocumentData | null>(null);
  const [tenantProfiles, setTenantProfiles] = useState<TenantSelectInfo[]>([]);

  AUTH.onIdTokenChanged(user => {
    if(user){
      user.getIdToken(false).then(res => {
        setIdToken(res);
      })
    }
  })

  const updateTenantId = async (tenantId:string) => {
    if(profile){
      const q = query(collection(db, "users"), where("uid", "==", profile.uid));
      const docs = await getDocs(q);
      if(docs.docs.length > 0) {
        const user = docs.docs[0].data();
        if(user.tenant_ids && user.tenant_ids.some((x:string) => x === tenantId)){
          await updateDoc(doc(db, "users", docs.docs[0].id), {
            tenant_id: tenantId
          }).then(() => {
            state.user?.getIdToken(true).then(() => window.location.reload());
          });
        }
      }
    }
  }


  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          setUserId(analytics, user.uid);
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);
          if(docs.docs.length > 0) {
            setProfile(docs.docs[0].data());

            if(docs.docs[0].data().tenant_id){
              const tenantRef = doc(db, "tenants", docs.docs[0].data().tenant_id);
              const docSnap = await getDoc(tenantRef);
              if(docSnap.exists()){
                setTenantProfile(docSnap.data());
                if (docs.docs[0].data().tenant_ids && docs.docs[0].data().tenant_ids.length > 0){
                  // User has access to multiple tenants
                  const tenantProfiles: TenantSelectInfo[] = [];
                  for (const tenantId of docs.docs[0].data().tenant_ids) {
                    const tenantRef = doc(db, "tenants", tenantId);
                    const tenantDocSnap = await getDoc(tenantRef);
                    if (tenantDocSnap.exists()) {
                      const tenantData = tenantDocSnap.data();
                      const tenantProfile: TenantSelectInfo = {
                        tenantId: tenantDocSnap.id,
                        tenantName: tenantData.name, // Again, replace 'name' with the correct field
                      };
                      tenantProfiles.push(tenantProfile);
                    }
                  }
                  // Set the state with all tenant profiles
                  setTenantProfiles(tenantProfiles);
                }
              }
            } else {
              //if user doc exists but doesn't have tenant id
              await setTenantFromDomain(user.email);
            }
          }

          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: true, user },
          });
        } else {
          setUserId(analytics, "");
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );


  const setTenantFromDomain = async (email: string | null) => {
    if (email) {
      const emailParts = email.split('@');
      const domain = emailParts[1];
      const tq = query(collection(db, "tenants"), where("domain", "array-contains", domain));
      const tenants = await getDocs(tq);
      if(tenants.docs.length > 0){
        setTenantProfile(tenants.docs[0].data());
        return tenants.docs[0];
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        isInitialized: state.isInitialized,
        isEmailVerified: state.isEmailVerified,
        method: 'firebase',
        user: {
          id: state?.user?.uid ?? null,
          emailVerified: !!state?.user?.emailVerified,
          accessToken: idToken ?? null,
          email: state?.user?.email ?? null,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: state?.user?.email && ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          roles: profile?.roles,
          isAdmin: profile?.roles?.includes('admin'),
          tenant_id: profile?.tenant_id,
          has_all_branches: profile?.has_all_branches,
          settings: tenantProfile?.settings,
          admin_email: tenantProfile?.admin_email,
          tenant_ids: profile?.tenant_ids,
          tenantSelectInfo: tenantProfiles
        },
        login,
        register,
        logout,
        signInWithGoogle,
        sendPasswordReset,
        sendEmailVerification: sendEmailVerification(state),
        updateTenantId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };