import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack, Image,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,

} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import * as React from 'react'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import logo from '../../assets/PriceSmith-Logo-Text-DesignX.svg';
import logoInverse from '../../assets/PriceSmith-Logo-Dark-DesignX.svg';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, signInWithGoogle } = useAuth();

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Image display={'flex'} maxH="20" src={useColorModeValue(logo, logoInverse)}/>
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
              Log in to your account
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">{"Don't have an account?"}</Text>
              <Link to={"/auth/register"}>
                <Button variant="link" colorScheme="blue">
                  Sign up
                </Button>
              </Link>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" type="email" onChange={(e) => setEmail(e.target.value)}/>
              </FormControl>
              <PasswordField setPassword={(x) => setPassword(x)}/>
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Link to={"/auth/reset"}>
                <Button variant="link" colorScheme="blue" size="sm">
                  Forgot password?
                </Button>
              </Link>
            </HStack>
            <Stack spacing="6">
              <Button variant="primary" onClick={() => login(email, password)}>Sign in</Button>
              <HStack>
                <Divider />
                <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                  or continue with
                </Text>
                <Divider />
              </HStack>
              <OAuthButtonGroup signInWithGoogle={() => signInWithGoogle()} />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Login;