import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { AuthProvider } from './context/FirebaseContext';
import { AccountProvider } from './context/AccountContext';
import { CustomRouter } from './routes/Routes';
import './assets/css/main.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import 'ag-grid-community/styles/ag-theme-balham.css'; // Theme
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Router } from 'react-router';

const App = () => {
  const queryClient = new QueryClient();
  return (
    <>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <AccountProvider>
            <CustomRouter />
          </AccountProvider>
        </ChakraProvider>
      </AuthProvider>
    </>
  );
};

export default App;
