import {
  MutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createBranch,
  deleteBranch, enableDisableAutoSync, exportChanges,
  getAllBranches, getDraftDashboardBanner, getDraftDashboardRecords,
  getDraftHistory,
  getMainBranchId,
  getPublishedDrafts,
  publishBranch,
} from './lmBranches.endpoints';
import { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import useAuth from '../../hooks/useAuth';
import { downloadFileFromAxiosResponse } from '../../utils/utils';

export const baseKey = process.env.REACT_APP_URL;

export const useGetMainBranch = () => {
  const { user } = useAuth();
  const { isLoading, isError, isFetching, data } = useQuery({
    queryKey: [
      `${baseKey}.${getMainBranchId.name}`,
      user?.accessToken ?? undefined,
    ],
    queryFn: getMainBranchId.query,
    enabled: !!user?.accessToken,
    staleTime: Infinity,
  });

  return {
    mainBranchIdLoading: isLoading,
    mainBranchIdFetching: isFetching,
    isError,
    mainBranchId: data,
  };
};

export const useGetAllBranches = (accessToken: string | undefined) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getAllBranches.name}`, accessToken ?? ''],
    queryFn: getAllBranches.query,
    enabled: !!accessToken,
  });

  return {
    allBranchesLoading: isLoading,
    allBranchesFetching: isFetching,
    isError,
    branches: data,
    refetchBranch: refetch,
    refetchingBranch: isFetching,
  };
};

interface Error {
  cause?: unknown;
}

export const useCreateBranch = (
  config: MutationOptions<number, Error, string> = {}
) => {
  const { onSuccess } = config;
  const toast = useToast();
  const { user } = useAuth();
  const client = useQueryClient();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [createBranch.name],
    mutationFn: (branchName: string) => {
      return createBranch.query(branchName, user?.accessToken ?? undefined);
    },
    onSuccess: (result, error, variable) => {
      const invalidDraft = [
        `${baseKey}.${getDraftHistory.name}`,
        user?.accessToken ?? undefined,
      ];

      void client.invalidateQueries({ queryKey: invalidDraft });

      toast({
        description: 'New branch created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });

      onSuccess?.(result, error, variable);
    },
    onError: (err: Error) => {
      const error = err as AxiosError;

      toast({
        description:
        error.message ?? 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    creatingBranch: isPending,
    createBranch: mutateAsync,
    createBranchIsError: isError,
    createBranchIsSuccess: isSuccess,
  };
};

export const useDeleteBranch = (
  config: MutationOptions<number, Error, number> = {}
) => {
  const client = useQueryClient();

  const { user } = useAuth();
  const { onSuccess } = config;

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [deleteBranch.name],
    mutationFn: (branchId: number) => {
      return deleteBranch.query(user?.accessToken ?? undefined, branchId);
    },
    onSuccess: (result, error, variable) => {
      const invalidKey = [
        `${baseKey}.${getAllBranches.name}`,
        user?.accessToken ?? '',
      ];

      const invalidDraft = [
        `${baseKey}.${getDraftHistory.name}`,
        user?.accessToken ?? undefined,
      ];

      void client.invalidateQueries({ queryKey: invalidKey });
      void client.invalidateQueries({ queryKey: invalidDraft });

      onSuccess?.(result, error, variable);
    },
    onError: (err: Error) => {
      const error = err as AxiosError;
      console.error(error)
    },
  });

  return {
    deletingBranch: isPending,
    deleteBranch: mutateAsync,
    isDeleteBranchError: isError,
    isDeleteBranchSuccess: isSuccess,
  };
};

export const useGetDraftHistory = () => {
  const { user } = useAuth();
  const { isLoading, isError, isFetching, data } = useQuery({
    queryKey: [
      `${baseKey}.${getDraftHistory.name}`,
      user?.accessToken ?? undefined,
    ],
    queryFn: getDraftHistory.query,
    enabled: !!user?.accessToken,
    staleTime: Infinity,
    refetchInterval:120000
  });

  return {
    getDraftHistoryLoading: isLoading,
    getDraftHistoryFetching: isFetching,
    isError,
    getDraftHistory: data,
  };
};

export const usePublishBranch = () => {
  const { user } = useAuth();
  const client = useQueryClient();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [publishBranch.name],
    mutationFn: (variables:{branchId: string | undefined, isPublished:boolean | undefined, accessToken:string| undefined}) => {
      return publishBranch.query(variables.branchId?.toString(), variables.isPublished, variables.accessToken ?? undefined);
    },
    onSuccess: () => {
      const invalidDraft = [`${baseKey}.${getAllBranches.name}`, user?.accessToken ?? ''];
      const invalidateDraftHistory = [
        `${baseKey}.${getDraftHistory.name}`,
        user?.accessToken ?? undefined,
      ]

      void client.invalidateQueries({ queryKey: invalidDraft });
      void client.invalidateQueries({ queryKey: invalidateDraftHistory });

    },
    onError: (err: Error) => {
      const error = err as AxiosError;
      console.error(error);
    },
  });

  return {
    publishingBranch: isPending,
    publishBranchAsync: mutateAsync,
    publishBranchIsError: isError,
    publishBranchIsSuccess: isSuccess,
  };
};

export const useGetPublishedDrafts = (accessToken: string | undefined) => {
  const { isLoading, isError,  data,  } = useQuery({
    queryKey: [`${baseKey}.${getPublishedDrafts.name}`, accessToken ?? ''],
    queryFn: getPublishedDrafts.query,
    enabled: !!accessToken,
  });

  return {
    PublishedDraftsLoading: isLoading,
    isError,
    PublishedDrafts: data,
  };
};

export const useGetDraftDashboardBanner = (accessToken: string | undefined) => {
  const { isLoading, isError, refetch, data } = useQuery({
    queryKey: [`${baseKey}.${getDraftDashboardBanner.name}`, accessToken ?? ''],
    queryFn: getDraftDashboardBanner.query,
    enabled: !!accessToken,
    gcTime: 0
  });
  return {
    isLoading,
    isError,
    refetch,
    data,
  };
}

export const useGetDraftDashboardRecords = (accessToken: string | undefined) => {
  const { isLoading, isError, refetch, data } = useQuery({
    queryKey: [`${baseKey}.${getDraftDashboardRecords.name}`, accessToken ?? ''],
    queryFn: getDraftDashboardRecords.query,
    enabled: !!accessToken,
    gcTime: 0
  });
  return {
    isLoading,
    isError,
    refetch,
    data,
  };
}

export const useEnableDisableAutoSync = () => {
  const { user } = useAuth();
  const client = useQueryClient();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [enableDisableAutoSync.name],
    mutationFn: (variables:{draftIds: number[], enable:boolean, accessToken:string| undefined}) => {
      return enableDisableAutoSync.query(variables.draftIds, variables.enable, variables.accessToken ?? undefined);
    },
    onSuccess: () => {
      const invalidDraft = [`${baseKey}.${getDraftDashboardRecords.name}`, user?.accessToken ?? ''];
      void client.invalidateQueries({ queryKey: invalidDraft });
    },
    onError: (err: Error) => {
      const error = err as AxiosError;
    },
  });

  return {
    isPending,
    mutateAsync,
    isError,
    isSuccess,
  };
};

export const useExportChanges = () => {
  const { user } = useAuth();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [exportChanges.name],
    mutationFn: (variables:{draftIds: number[]}) => {
      return exportChanges.query(variables.draftIds, user?.accessToken ?? undefined);
    },
    onSuccess: (res) => {
      downloadFileFromAxiosResponse(res);
    },
    onError: (err: Error) => {
      const error = err as AxiosError;
    },
  });

  return {
    isPending,
    mutateAsync,
    isError,
    isSuccess,
  };
};
