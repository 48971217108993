import { ListManagerProvider, useListManager } from '../../hooks/useListManager';
import React, { useMemo } from 'react';
import { NavItem } from '../../../@types/BackendViewModels';
import { FiActivity, FiBook, FiHome } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import PageTopElementBox from '../../components/misc/PageTopElementBox';
import { BreadcrumbText } from '../../components/misc/text/TextComponents';
import BranchSelect from './components/BranchSelect';
import { Alert, AlertIcon, AlertTitle, Badge, Button, Flex, Progress, Text, Box, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import ProcessingStatusToast from './components/ProcessingStatusToast';
import NavigationContainer from './components/NavigationContainer';
import { useGetSyncProductCount, useSyncThirdPartySystem } from '../../apis/Pricing';
import { useGetAllBranches } from '../../apis/LMBranches';
import { Outlet } from 'react-router-dom';
import SyncAlert from './components/SyncAlert';
import DraftStatusBadge from './dashboard/components/DraftStatusBadge';
import { useGetDraftDashboardBanner } from '../../apis/LMBranches';
import {

  useGetDraftDashboardRecords, 
} from '../../apis/LMBranches';
import DraftActionMenu from './dashboard/components/DraftActionMenu'; 
import { format } from 'timeago.js';


/* 
type DraftContextContainerProps = {
  draftData: DraftDashboardRecord[]; // Draft records passed as a prop
}; */

/* interface DraftsTableProps {
  data:DraftDashboardRecordDto[],
  onSyncDataClick:(draftIds:number[]) => void,
  onEnableDisableAutoSync:(draftIds: number[], enable: boolean) => void,
  onPublishUnpublishDrafts:(draftIds: number[], enable: boolean) => Promise<void>,
  onDeleteDrafts:  (draftIds: number[]) => Promise<void>;
  onCreateDraft: (draftName:string) => void;
  onScheduleDrafts: (draftIds: number[], dateTime: Date) => Promise<void>,
  onUnscheduleDrafts: (draftIds: number[]) => Promise<void>, */

const DraftContextContainer = () => {
  const { user } = useAuth();
  const listManagerContext = useListManager();
  const {data: draftData, isLoading, refetch, isError} = useGetDraftDashboardRecords(user?.accessToken ?? undefined);
  const {data: bannerData, isLoading: bannerLoading} = useGetDraftDashboardBanner(user?.accessToken ?? undefined);

  const params = useParams();
  const {
    SyncProductCount,
    SyncProductCountRefetch,
    SyncProductCountRefetching,
  } = useGetSyncProductCount(user?.accessToken ?? undefined);

  const showSyncAlert = useMemo(() => {
    if (
      (SyncProductCount && SyncProductCount?.addedProductCount > 0) ||
      (SyncProductCount && SyncProductCount?.updatedProductCount > 0)
    ) {
      return true;
    }
    return false;
  }, [SyncProductCount]);


  const items: NavItem[] = React.useMemo( 
    () => [
      {
        label: 'Price Lists',
        to: `/pricing/b/${listManagerContext.branch?.id}`,
        icon: <FiHome strokeWidth={1} size={20} />,
        paths: [
          `/pricing/b/${listManagerContext.branch?.id}`,
          `/pricing`,
          `/pricing/b/${listManagerContext.branch?.id}/s/${params.segmentId}`,
          `/pricing/b/${listManagerContext.branch?.id}/s/${params.segmentId}/d/${params.detailsId}`,
        ],
      },
      {
        label: 'Product Data',
        to: `/pricing/b/${listManagerContext.branch?.id}/productDataManager`,
        icon: <FiActivity strokeWidth={1} size={20} />,
        paths: [
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager`,
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager/p/${params.products_Group}`,
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager/all`,
        ],
      },
      {
        label: 'Compare',
        //to: `/pricing/compare/b/${params.branchId}`,
        to:  `/pricing/b/${listManagerContext.branch?.id}/compare`,
        icon: <FiBook strokeWidth={1} size={20} />,
        paths: [
          `/pricing/b/${listManagerContext.branch?.id}/compare`,
      ],
      },
    ],
    [params, listManagerContext.branch?.id]
  );


  const topElement = React.useMemo(
    () => {  
      const draftRecord = draftData?.find(record => record.id === listManagerContext.branch?.id);

      return (
    
      <>
        <PageTopElementBox>
          <Flex justifyContent='space-between'>
            <HStack spacing={'20px'}>
              <HStack spacing={'10px'}>
                {!listManagerContext.branch?.isMain && (
                <Breadcrumb separator="/" fontSize={'16px'}>
                  <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={`/pricing/`}>
                      <BreadcrumbText>Drafts</BreadcrumbText>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
                  )}
                {!listManagerContext.branch?.isMain && (
                <Text fontSize='18px'>/</Text>
                 )}
                <BranchSelect/>
              </HStack>
              <DraftStatusBadge 
                status={
                  draftRecord?.draftStatus 
                  ?? (bannerData?.lastMerge?.value ? `Last updated ${format(bannerData.lastMerge.value)}` : 'Unknown')
                } 
/>
            </HStack>
           {/*  <DraftActionMenu
              isDraftActionsDisabled={!areAnyNodesSelected}
              onPublishUnpublishDrafts={innerOnPublishUnpublishDrafts}
              onDeleteDrafts={innerOnDeleteDrafts}
              onCreateDraft={onCreateDraft}
              onScheduleDrafts={innerOnScheduleDrafts}
              onUnscheduleDrafts={innerOnUnscheduleDrafts}/> */}
          </Flex>

             {false && (<SyncAlert addedProductCount={SyncProductCount?.addedProductCount} updatedProductCount={SyncProductCount?.updatedProductCount} />)}
        </PageTopElementBox>
          <NavigationContainer
            items={items}
            disableCompare={!!listManagerContext.branch?.isMain}
          >
            <>
              {(listManagerContext.isNavigating ||
                listManagerContext.isLoading) && (
                <Progress size="xs" isIndeterminate />
              )}
            </>
          </NavigationContainer>
      </>
    );
  },
    [
      SyncProductCount?.addedProductCount,
      SyncProductCount?.updatedProductCount,
      items,
      listManagerContext,
      draftData,
      bannerData    ]
  );


  return (
    <>
      {topElement}
        <Outlet />
    </>
  );
};

export default DraftContextContainer;

