import z from 'zod';
export interface ProductDataManager {
  Item_IID: string;
  Item_BrandName: string | null;
  Item_BrandCode: string | null;
  Item_BrandID: string | null;
  Item_SubBrand: string | null;
  Item_Category: string | null;
  Item_Name: string | null;
  Item_ActiveMgmt: string | null;
  Product_GroupID: string;
  Item_OrderID: string | undefined;
}

const itemHistory = z
  .object({
    id: z.number(),
    tenantId: z.string(),
    itemId: z.string(),
    propertyName: z.string(),
    previousValue: z.string(),
    newValue: z.string(),
    mergeDateTime: z.string(),
    userId: z.string(),
  })
  .optional();


  // const updateProductBatch = z.object({
  //   draftId: z.string().optional(),
  //   productIds: z.array(z.string()),
  //   productProperties: z.record(
  //     z.string(), 
  //     z.union([z.string(), z.number(), z.date()]) 
  //   ).optional(),
  // })

export type ItemHistory = z.infer<typeof itemHistory>;
// export type UpdateProductBatch = z.infer<typeof updateProductBatch>;

type DefaultData = { [key: string]: string | object | number | boolean | null };

export interface UpdateProductBatch 
  {
    draftId: string | undefined,
    productIds: string[] | null,
    productProperties: DefaultData | undefined
  
}