import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { Box, Tbody, BoxProps,useTheme } from '@chakra-ui/react';

interface DynamicHeightContentProps {
  footerHeight: number;
  background?: string;
  children: ReactNode;
  style?: React.CSSProperties;
  defaultHeight?: string | number;
}

const DynamicHeightContent: React.FC<DynamicHeightContentProps & BoxProps> = ({
  footerHeight,
  background,
  children,
  style,
  defaultHeight= '800px',
  ...rest
}) => {
  const [dynamicHeight, setDynamicHeight] = useState<string | number>('auto');
  const contentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { breakpoints } = theme;

  const emToPx = useCallback((em:number) => {
    return em * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }, []);

  const updateContentHeight = useCallback(() => {
    const breakpointInEm = parseFloat(breakpoints.md); // Assuming your theme has a 'md' breakpoint in 'em'
    const breakpointInPx = emToPx(breakpointInEm);
    if (window.innerHeight >= breakpointInPx) { // Use Chakra UI's breakpoint values or your custom ones
      if (contentRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect();
        const contentOffsetTop = contentRect.top; // Get the top offset position of the content div
        const remainingHeight = window.innerHeight - contentOffsetTop - footerHeight;
        setDynamicHeight(remainingHeight);
      }
    } else {
      // For smaller screens, use the defaultHeight
      setDynamicHeight(defaultHeight);
    }
  }, [footerHeight, defaultHeight, breakpoints.md, emToPx]);

  useEffect(() => {
    // Initial update
    updateContentHeight();

    // Update on window resize
    window.addEventListener('resize', updateContentHeight);

    // Clean up event listener
    return () => window.removeEventListener('resize', updateContentHeight);
  }, [footerHeight, updateContentHeight]); // Dependency on footerHeight in case it changes

  return (
    <Box
      ref={contentRef}
      style={{
        height: dynamicHeight,
        background: background,
        overflowY: 'auto',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DynamicHeightContent;
