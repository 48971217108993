import React, { createContext, useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { AccountDto, BranchOrgChart, BranchOrgChartResponse } from "../../@types/BackendViewModels";

interface AccountContextProperties {
  isLoadingBranches: boolean;
  isLoadingAccounts: boolean;
  branch: BranchOrgChart | null,
  branches: BranchOrgChart[],
  account: AccountDto | null,
  accounts: AccountDto[]
}

interface AccountDispatchContextProperties {
  setActiveBranch: (branch: BranchOrgChart | null) => void;
  clearActiveBranch: () => void;
  setActiveAccount: (account: AccountDto | null) => void;
  clearActiveAccount: () => void;
  createTempAccount: (accountDto: AccountDto | null) => void;
  deleteTempAccount: (customerIid: string | null | undefined) => void;
}

// interface 

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const AccountContext = createContext<AccountContextProperties | undefined>(undefined);
const AccountDispatchContext = createContext<AccountDispatchContextProperties | undefined>(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function AccountProvider({ children }: React.PropsWithChildren) {
  const {isAuthenticated, isInitialized, user } = useAuth();
  const [branches, setBranches] = useState<BranchOrgChart[]>([]);
  const [branch, setBranch] = useState<BranchOrgChart | null>(null);
  const [isLoadingBranches, setIsLoadingBranches] = useState(true);
  const [accounts, setAccounts] = useState<AccountDto[]>([]);
  const [account, setAccount] = useState<AccountDto | null>(null);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  useEffect(() => {
    if(!user || !isAuthenticated || !isInitialized){
      setBranches([]);
      setBranch(null);
      return;
    }
    axios.get<BranchOrgChartResponse>(`${process.env.REACT_APP_URL}/Branches`,{responseType: 'json', headers: { Authorization: `Bearer ${user.accessToken}` }})
      .then(res => {
          const { branches, cachedBranch } = res.data;
          setBranches(branches);
          setBranch(cachedBranch);
          setIsLoadingBranches(false);
          setIsLoadingAccounts(true);
        })
        .catch(err => {
          console.error(err);
          setIsLoadingBranches(false);
        });
  }, [isAuthenticated, isInitialized, user])

  const loadAccounts = React.useCallback(() => {
    if(user){
      setIsLoadingAccounts(true);
      const url = branch ? `${process.env.REACT_APP_URL}/Accounts/${branch?.branchKey}` : `${process.env.REACT_APP_URL}/Accounts`;
      axios.get(url,{responseType: 'json', headers: { Authorization: `Bearer ${user.accessToken}` }})
        .then(res => {
          setAccounts(res.data);
          setIsLoadingAccounts(false);
        })
        .catch(err => {
          console.error(err);
          setIsLoadingAccounts(false);
        });

    } else {
      setAccounts([]);
      setAccount(null);
    }
  }, [user, branch])

  useEffect(() => {
    if(!user || !isAuthenticated || !isInitialized) {
      setAccounts([]);
      setAccount(null);
      return;
    }

    if(!branches || branches.length === 0){
      return;
    }
    //if more than 1 branch and dont have 1 selected.
    if(branches.length > 1 && !branch){
      return;
    }

    loadAccounts();

  }, [user, branch, branches, isAuthenticated, isInitialized, loadAccounts])

  const setActiveBranch = (branch: BranchOrgChart | null) => {
    if(user && branch){
      setAccount(null);
      setAccounts([]);
      setBranch(branch);
      setIsLoadingAccounts(true);
      axios.patch(`${process.env.REACT_APP_URL}/Branches/Cache`, { branchKey: branch.branchKey }, {responseType: 'json', headers: { Authorization: `Bearer ${user.accessToken}` }})
        .then()
        .catch(err => console.error(err));
    } else {
      clearActiveBranch();
    }
  }

  const createTempAccount = (accountDto: AccountDto | null) => {
    if(user && accountDto){
      axios.post(`${process.env.REACT_APP_URL}/Accounts`, accountDto, {responseType: 'json', headers: { Authorization: `Bearer ${user.accessToken}` }})
        .then(res => {
          const newAccount = {
            customer_IID: res.data,
            ...accountDto
          };
          setAccounts(prev => [...prev, newAccount]);
          setActiveAccount(newAccount);
        })
        .catch(err => console.error(err));
    }
  }

  const deleteTempAccount = (customerIid: string | null | undefined) => {
    if(user && customerIid){
      axios.delete(`${process.env.REACT_APP_URL}/Accounts/${customerIid}`, {responseType: 'json', headers: { Authorization: `Bearer ${user.accessToken}` }})
        .then(() => {
          setActiveAccount(null);
          loadAccounts();
        })
        .catch(err => console.error(err));
    }
  }

  const clearActiveBranch = () => {
    setBranch(null);
    setAccount(null);
    setAccounts([]);
  }

  const setActiveAccount = (account: AccountDto | null) => {
    if(account){
      setAccount(account);
    } else {
      clearActiveAccount();
    }
  }

  const clearActiveAccount = () => {
    setAccount(null);
  }

  return (
    <AccountContext.Provider value={{
      isLoadingBranches,
      isLoadingAccounts,
      branch,
      branches,
      account,
      accounts,
    }}>
      <AccountDispatchContext.Provider value={{
        setActiveBranch,
        clearActiveBranch,
        setActiveAccount,
        clearActiveAccount,
        createTempAccount,
        deleteTempAccount
      }}>
        {children}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  );
}

export { AccountProvider, AccountContext, AccountDispatchContext };

/*

 */