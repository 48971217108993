import React from 'react';
import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { FiEdit, FiCheckCircle, FiHelpCircle } from 'react-icons/fi'; // Feather icons
import { MdPublish, MdUpdate, MdRemoveCircleOutline } from 'react-icons/md'; // Material icons
import { BiCurrentLocation } from "react-icons/bi";

// Define the interface for BadgeProps
interface BadgeProps {
  status: string;
}

const DraftStatusBadge = ({ status }: BadgeProps) => {
  // Use the useColorModeValue hook to determine if dark mode is active
  const isDarkMode = useColorModeValue(false, true);
// Function to get all properties based on the status
  const getBadgeProperties = React.useCallback((status: string) => {
    switch (status) {
      case 'Draft':
        return {
          textColor: isDarkMode ? '#1A2320' : '#A3A3A3',
          buttonColor: isDarkMode ? '#C0C0C0' : '#FFFFFF',
          Icon: FiEdit,
          iconSize: '10px',
          borderColor: isDarkMode ? '#1A2320' : '#A3A3A3',
          rotate: 0,
        };
      case 'Published':
        return {
          textColor: isDarkMode ? '#1A2320' : '#FFFFFF',
          buttonColor: isDarkMode ? '#FFB247' : '#E68100',
          Icon: MdPublish,
          iconSize: '12px',
          borderColor: isDarkMode ? '#1A2320' : '#E68100',
        };
      case 'Scheduled':
        return {
          textColor: isDarkMode ? '#1A2320' : '#FFFFFF',
          buttonColor: isDarkMode ? '#82AEE6' : '#3182CE',
          Icon: MdUpdate,
          iconSize: '12px',
          borderColor: isDarkMode ? '#1A2320' : '#3182CE',
          rotate: 0,
        };
      case 'Merged':
        return {
          textColor: isDarkMode ? '#1A2320' : '#FFFFFF',
          buttonColor: isDarkMode ? '#4AC162' : '#249B3E',
          Icon: FiCheckCircle,
          iconSize: '10px',
          borderColor: isDarkMode ? '#1A2320' : '#249B3E',
          rotate: 0,
        };
      case 'Deleted':
        return {
          textColor: isDarkMode ? '#1A2320' : '#FFFFFF',
          buttonColor: isDarkMode ? '#DD6A50' : '#A92C18',
          Icon: MdRemoveCircleOutline,
          iconSize: '12px',
          borderColor: isDarkMode ? '#1A2320' : '#A92C18',
          rotate: 0,
        };
      default:
        return {
          textColor: isDarkMode ? '#1A2320' : '#1A2320',
          buttonColor: isDarkMode ? '#A3A3A3' : '#FFFFFF',
          Icon: BiCurrentLocation,
          iconSize: '12px',
          borderColor: isDarkMode ? '#1A2320' : '#1A2320',
        };
    }

  }, [isDarkMode]);
  // Get all the properties based on the status and color mode
  const { textColor, buttonColor, borderColor, Icon, iconSize, rotate } = getBadgeProperties(status);

  return (
    <Box
      backgroundColor={buttonColor} // Use backgroundColor instead of colorScheme
      color={borderColor}
      border='1px' // Border for outline variant
      borderRadius='8px'
      h='24px'
      px='8px'
      display='inline-flex'
      alignItems='center'
    >
      <Stack direction="row" spacing='4px' align='center' justify='center'>
        {/* Apply rotation conditionally */}
        <Icon style={{ fontSize: iconSize, transform: `rotate(${rotate}deg)`, color: textColor}} />
        <Text
          color={textColor} // Text color
          fontSize='12px'
          lineHeight='14px'
          fontStyle="Inter"
          fontWeight="semibold">{status}
        </Text>
      </Stack>
    </Box>
  );
};

export default DraftStatusBadge;