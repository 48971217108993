// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = process.env.REACT_APP_ENV === 'PROD'
  ?{
    apiKey: "AIzaSyCs3b7RkN6HlolT7kivd2U9EXnTB6fApWY",
    authDomain: "pricesmith-prod.firebaseapp.com",
    projectId: "pricesmith-prod",
    storageBucket: "pricesmith-prod.appspot.com",
    messagingSenderId: "160613870333",
    appId: "1:160613870333:web:18501015ce37e4c995ec94",
    measurementId: "G-J18YDVRYSE"
  }
  : {
  apiKey: "AIzaSyChblxpxcOWWPpiREHSTwRMpjPvDq43OpE",
  authDomain: "pricesmith-dev.firebaseapp.com",
  projectId: "pricesmith-dev",
  storageBucket: "pricesmith-dev.appspot.com",
  messagingSenderId: "772526352066",
  appId: "1:772526352066:web:cf510577737ebd6a6d7d5b",
  measurementId: "G-PCDFWNQ5ZF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try{
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if(docs.docs.length === 0){
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email
      });
    }
  } catch(err){
    console.error(err);
    alert(err.message);
  }
}

const logInWithEmailAndPassword = async(email, password) => {
  try{
    await signInWithEmailAndPassword(auth, email, password);
  }catch(err){
    console.error(err);
    alert(err.message);
  }
}

const registerWithEmailAndPassword = async(name, email, password) => {
  try{
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local"
    })
  }catch(err){
    console.error(err);
    alert(err.message);
  }
}

const sendPasswordReset = async(email) => {
  try{
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  }catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const logout = () => {
  signOut(auth);
};

const logAnalyticsEvent = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
}

export {
  auth,
  analytics,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  logAnalyticsEvent,
  firebaseConfig
};