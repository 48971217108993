import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Center, useColorModeValue, useToken } from '@chakra-ui/react';
import DynamicHeightContent from './DynamicHeightContent';

const LoadingScreen = () => {
  const [light, dark] = useToken('colors', [
    'brand.secondary.light',
    'brand.secondary.dark',
  ]);
  return (
    <DynamicHeightContent footerHeight={0} w="full">
      <Center h={'100%'}>
        <BeatLoader size={30} color={useColorModeValue(light, dark)} />
      </Center>
    </DynamicHeightContent>
  );
};

export default LoadingScreen;
