/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react';
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
import LoadingScreen from '../components/misc/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import SettingBasedGuard from '../guards/SettingBasedGuard';
import { Box } from '@chakra-ui/react';
import AssetsDashboard from '../pages/Assets/AssetsDashboard';
import SalesDashboard from '../pages/Sales/SalesDashboard';
import PricingLayout from '../pages/PricingManager/PricingLayout';
import DraftContextContainer from '../pages/PricingManager/DraftContextContainer';

// components

// ----------------------------------------------------------------------

//eslint-disable-next-line
const Loadable =
  <T extends React.ComponentType<any>>(Component: T) =>
  (props: React.ComponentProps<T>) => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        {/* Spread the props into the Component */}
        <Component {...props} />
      </Suspense>
    );
  };

export function CustomRouter() {
  const router = createBrowserRouter([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <Box h={'100vh'}>
              <GuestGuard>
                <Login />
              </GuestGuard>
            </Box>
          ),
        },
        {
          path: 'register',
          element: (
            <Box h={'100vh'}>
              <GuestGuard>
                <Register />
              </GuestGuard>
            </Box>
          ),
        },
        {
          path: 'reset',
          element: (
            <Box h={'100vh'}>
              <GuestGuard>
                <Reset />
              </GuestGuard>
            </Box>
          ),
        },
        {
          path: 'emailVerification',
          element: (
            <Box h={'100vh'}>
              <GuestGuard>
                <EmailVerification />
              </GuestGuard>
            </Box>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <Reset /> },
      ],
    },
    {
      path: 'admin/*',
      element: (
        <Box h={'100vh'}>
          <AuthGuard>
            <RoleBasedGuard accessibleRoles={['admin']}>
              <GlobalDashboard>
                <AdminToolsContainer />
              </GlobalDashboard>
            </RoleBasedGuard>
          </AuthGuard>
        </Box>
      ),
      children: [
        {
          element: <Navigate to={'/admin/locationApprovals'} replace />,
          index: true,
        },
        { element: <BranchApprovalsContainer />, path: 'locationApprovals' },

        {
          element: <PriceChangeApprovalsContainer />,
          path: 'priceChangeApprovals',
        },

        { element: <RoleManagementContainer />, path: 'roleManagement' },
        { element: <UserAssignmentContainer />, path: 'userAssignment' },
      ],
    },
    {
      path: 'profile',
      element: (
        <Box h={'100vh'}>
          <AuthGuard>
            <GlobalDashboard>
              <UserProfilePage />
            </GlobalDashboard>
          </AuthGuard>
        </Box>
      ),
    },
    {
      path: 'pricing/*',
      element: (
        <Box h={'100vh'}>
          <AuthGuard>
            <RoleBasedGuard accessibleRoles={['listmanager']}>
              <SettingBasedGuard setting={'listmanager'}>
                <PricingLayout />
              </SettingBasedGuard>
            </RoleBasedGuard>
          </AuthGuard>
        </Box>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <PricingDashboard />,
        },
        {
          path: 'b/*',
          element: <DraftContextContainer />,
          children: [
            {
              index: true,
              element: (
                <SegmentTreeContainer />
              ),
            },
            {
              path: ':branchId',
              element: (
                <SegmentTreeContainer />
              ),
            },
            {
              path: ':branchId/s/:segmentId/*',
              element: (
                  <ProductPricingContainer />
              ),
              children: [
                {
                  index: true,
                  element: <ProductPricingTable useProductGroups={false} />,
                },
              ],
            },
            {
              path: ':branchId/productDataManager',
              element: <ProductDataManagerTable />
            },
            {
              path: ':branchId/compare/',
              element: (
                  <CompareBranchContainer />
              ),
            },
          ]
          
        },

        {
          path: 'drafts',
          element: <DraftsContainer />
        },
        {
          path: 'schedule/*',
          element: <ScheduledMerge />
        },

        {
          path: 'property-settings/*',
          element: (
              <SchemaLibraryContainer />
          ),
          children: [
            {
              index: true,
              element: <SchemaLibraryPropertiesTable />,
            },
            {
              path: 'groups',
              element: <SchemaLibraryGroupsTable />,
            },
            {
              path: 'archived',
              element: <SchemaLibraryArchivedTable />,
            },
          ],
        },
        {
          path: 'import',
          element: (
              <ImportProductsContainer />
          ),
        },
        {
          path: 'formulas',
          element: (
              <FormulasContainer />
          ),
        },
      ],
    },
    {
      path: 'sales/requests',
      element: (
        <Box h={'100vh'}>
          <AuthGuard>
            <SalesDashboard showContext={false}>
              <PriceChangeApprovalsContainer />
            </SalesDashboard>
          </AuthGuard>
        </Box>
      ),
    },
    {
      path: 'sales/pricesheets',
      element: (
        <SettingBasedGuard setting={'pricesheets'}>
          <SalesDashboard showContext={true}>
            <PriceSheetsContainer />
          </SalesDashboard>
        </SettingBasedGuard>
      ),
    },
    // Dashboard Routes
    {
      path: '*',
      element: (
        <Box h={'100vh'}>
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        </Box>
      ),
      children: [
        {
          index: true,
          element: (
            <GlobalDashboard>
              <HomeDashboard />
            </GlobalDashboard>
          ),
        },
        {
          path: 'assets',
          children: [
            {
              index: true,
              element: (
                <SettingBasedGuard setting={'pricesheets'}>
                  <AssetsDashboard showContext={false}>
                    <AssetsContainer />
                  </AssetsDashboard>
                </SettingBasedGuard>
              ),
            },
            {
              path: 'distribute',
              element: (
                <SettingBasedGuard setting={'pricesheets'}>
                  <RoleBasedGuard accessibleRoles={['distribute']}>
                    <AssetsDashboard showContext={true}>
                      <DistributionContainer />
                    </AssetsDashboard>
                  </RoleBasedGuard>
                </SettingBasedGuard>
              ),
            },
            {
              path: 'manage',
              element: (
                <SettingBasedGuard setting={'pricesheets'}>
                  <RoleBasedGuard accessibleRoles={['admin']}>
                    <AssetsDashboard showContext={false}>
                      <ManageLayoutsContainer />
                    </AssetsDashboard>
                  </RoleBasedGuard>
                </SettingBasedGuard>
              ),
              children: [
                {
                  element: <Navigate to={'/manage/priceTables'} replace />,
                  index: true,
                },
                { path: 'layouts', element: <ManageLayouts /> },
                {
                  path: 'styles',
                  element: <ManageLayoutPartials vm={'ManageLayoutStylesVM'} />,
                },
                {
                  path: 'headers',
                  element: (
                    <ManageLayoutPartials vm={'ManageLayoutHeadersVM'} />
                  ),
                },
                {
                  path: 'tables',
                  element: <ManageLayoutPartials vm={'ManageLayoutTablesVM'} />,
                },
                {
                  path: 'templateTables',
                  element: (
                    <ManageLayoutPartials vm={'ManageLayoutTemplateTablesVM'} />
                  ),
                },
                {
                  path: 'salespersons',
                  element: (
                    <ManageLayoutPartials vm={'ManageLayoutSalespersonsVM'} />
                  ),
                },
                {
                  path: 'footers',
                  element: (
                    <ManageLayoutPartials vm={'ManageLayoutFootersVM'} />
                  ),
                },
                { path: 'templates', element: <ManageTemplates /> },
                { path: 'categories', element: <ManageTemplateCategories /> },
                { path: 'brands', element: <ManageBrands /> },
                { path: 'businessUnits', element: <ManageBusinessUnits /> },
                { path: 'priceTables', element: <ManagePriceTables /> },
                {
                  path: 'marketingMaterials',
                  element: <ManageMarketingMaterials />,
                },
              ],
            },
          ],
        },

        {
          path: 'sales/',
          element: (
            <RoleBasedGuard accessibleRoles={['accountmanager']}>
              <SettingBasedGuard setting={'accountmanager'}>
                <SalesDashboard showContext={true}>
                  <SalesContainer />
                </SalesDashboard>
              </SettingBasedGuard>
            </RoleBasedGuard>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard accessibleRoles={['dashboard']}>
              <SettingBasedGuard setting={'accountmanager'}>
                <SalesInsights />
              </SettingBasedGuard>
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '/error',
      element: (
        <Box h={'100vh'}>
          <ErrorPage />
        </Box>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../components/auth/Login')));
const Register = Loadable(lazy(() => import('../components/auth/Register')));
const Reset = Loadable(lazy(() => import('../components/auth/Reset')));
const EmailVerification = Loadable(
  lazy(() => import('../components/auth/EmailVerification'))
);
const ErrorPage = Loadable(lazy(() => import('../components/misc/ErrorPage')));

// Dashboard

const HomeDashboard = Loadable(
  lazy(() => import('../pages/home/HomeDashboard'))
);

const Dashboard = Loadable(lazy(() => import('../Dashboard')));
const GlobalDashboard = Loadable(lazy(() => import('../GlobalDashboard')));
const PriceSheetsContainer = Loadable(
  lazy(() => import('../pages/Sales/PriceSheets/PriceSheetsContainer'))
);
const AssetsContainer = Loadable(
  lazy(() => import('../pages/Assets/AssetsContainer'))
);
const ManageLayoutsContainer = Loadable(
  lazy(() => import('../components/ManageLayoutsContainer'))
);
const ManageLayouts = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageLayouts'))
);
const ManageLayoutPartials = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageLayoutPartials'))
);
const ManageTemplates = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageTemplates'))
);
const ManageTemplateCategories = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageTemplateCategories'))
);
const ManageBrands = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageBrands'))
);
const ManageBusinessUnits = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageBusinessUnits'))
);
const ManagePriceTables = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManagePriceTables'))
);
const ManageMarketingMaterials = Loadable(
  lazy(() => import('../pages/Assets/Manage/ManageMarketingMaterials'))
);
const DistributionContainer = Loadable(
  lazy(() => import('../pages/Assets/Distribute/DistributionContainer'))
);

const AdminToolsContainer = Loadable(
  lazy(() => import('../components/admin/AdminToolsContainer'))
);
const BranchApprovalsContainer = Loadable(
  lazy(() => import('../components/admin/BranchApprovalsContainer'))
);
const PriceChangeApprovalsContainer = Loadable(
  lazy(() => import('../pages/Sales/MyRequests/PriceChangeApprovalsContainer'))
);
const UserProfilePage = Loadable(
  lazy(() => import('../components/profile/UserProfilePage'))
);
const SalesContainer = Loadable(
  lazy(() => import('../pages/Sales/SalesContainer'))
);
const SalesInsights = Loadable(lazy(() => import('../pages/Sales/Dashboard')));
const RoleManagementContainer = Loadable(
  lazy(() => import('../components/privileges/RoleManagementContainer'))
);
const UserAssignmentContainer = Loadable(
  lazy(() => import('../components/privileges/UserAssignmentContainer'))
);

const PricingContainer = Loadable(
  lazy(() => import('../pages/PricingManager/PricingContainer'))
);

const PricingDashboard = Loadable(
  lazy(() => import('../pages/PricingManager/dashboard/Dashboard'))
);

const SegmentTreeContainer = Loadable(
  lazy(
    () => import('../pages/PricingManager/Pricing/Prices/SegmentTreeContainer')
  )
);

const ProductPricingTable = Loadable(
  lazy(
    () => import('../pages/PricingManager/Pricing/Prices/ProductPricingTable')
  )
);
const DraftsContainer = Loadable(
  lazy(() => import('../pages/PricingManager/Drafts/DraftsContainer'))
);
const CompareBranchContainer = Loadable(
  lazy(
    () =>
      import('../pages/PricingManager/Pricing/Compare/CompareBranchContainer')
  )
);

const ScheduledMerge = Loadable(
  lazy(() => import('../pages/PricingManager/Schedule/ScheduledMerge'))
);

const ProductDataManagerTable = Loadable(
  lazy(
    () =>
      import(
        '../pages/PricingManager/Pricing/ProductData/ProductDataManagerTable'
      )
  )
);

const ProductPricingContainer = Loadable(
  lazy(
    () =>
      import('../pages/PricingManager/Pricing/Prices/ProductPricingContainer')
  )
);

const SchemaLibraryContainer = Loadable(
  lazy(
    () => import('../pages/PricingManager/SchemaLibrary/SchemaLibraryContainer')
  )
);

const SchemaLibraryPropertiesTable = Loadable(
  lazy(
    () =>
      import('../pages/PricingManager/components/SchemaLibraryPropertiesTable')
  )
);

const SchemaLibraryGroupsTable = Loadable(
  lazy(
    () => import('../pages/PricingManager/components/SchemaLibraryGroupsTable')
  )
);

const SchemaLibraryArchivedTable = Loadable(
  lazy(
    () =>
      import('../pages/PricingManager/components/SchemaLibraryArchivedTable')
  )
);

const ImportProductsContainer = Loadable(
  lazy(
    () => import('../pages/PricingManager/ImportExport/ImportProductsContainer')
  )
);

const FormulasContainer = Loadable(
  lazy(() => import('../pages/PricingManager/Formulas/FormulasContainer'))
);
