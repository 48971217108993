import React from 'react';
import {
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import useAuth from '../hooks/useAuth'
import { CustomUser, FireBaseSetting } from '../context/FirebaseContext';

const useCurrentSettings = (user: CustomUser | null) => {
  return user?.settings;
};

interface SettingBasedGuardProps extends React.PropsWithChildren {
  setting: FireBaseSetting;
}

export default function SettingBasedGuard({ setting, children }: SettingBasedGuardProps) {
  const { user } = useAuth();
  const settings = useCurrentSettings(user);

  if (!(settings?.[setting]?.enabled)) {
    return (
      <Center>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Permission Denied</AlertTitle>
          <AlertDescription>You do not have permission to access this page.</AlertDescription>
        </Alert>
      </Center>
    );
  }

  return <>{children}</>;
}
