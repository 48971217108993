import React from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import logo from '../../assets/PricesmithLogoBase.svg';
import logoInverse from '../../assets/PricesmithLogoInverse.svg';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const EmailVerification = () => {
  const { sendEmailVerification, user, logout } = useAuth();
  return(
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Image display={'flex'} maxH="20" src={useColorModeValue(logo, logoInverse)}/>
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
              Send Verification Email
            </Heading>
            <HStack spacing="1" justify="center">
              <Link to={"/auth/login"}>
                <Button variant="link" colorScheme="blue" onClick={() => logout()}>
                  Nevermind, go back
                </Button>
              </Link>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" type="email" value={user?.email ?? undefined} isDisabled/>
              </FormControl>
            </Stack>
            <Stack spacing="6">
              <Button variant="primary" onClick={() => sendEmailVerification()}>Resend email verification</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default EmailVerification;