import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getColumnValues, getProductDataSchemaLibraryColumns, getSchemaLibrary } from './schemaLibrary.endpoints';
import { UseQueryResult } from '@tanstack/react-query/build/modern';
import useAuth from '../../hooks/useAuth';

export const baseKey = process.env.REACT_APP_URL;

export const useGetSchemaLibrary = (
  objectType: string | undefined,
  accessToken: string | undefined
) => {
  const queryFn = ({
    queryKey,
    meta,
    signal,
  }: QueryFunctionContext<[string, string | undefined]>) => {
    return getSchemaLibrary.query(
      { queryKey: queryKey, signal: signal, meta: meta },
      accessToken
    );
  };

  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getSchemaLibrary.name}`, objectType],
    queryFn, // Use the adjusted queryFn
    enabled: !!objectType && !!accessToken, // Ensure the query runs only if both segmentId and accessToken are available
    staleTime: Infinity,
  });

  return {
    SchemaLibraryLoading: isLoading,
    SchemaLibraryFetching: isFetching,
    SchemaLibraryFetch: refetch,
    isError,
    SchemaLibraryData: data,
  };
};

export const useGetProductDataSchemaLibraryColumns = (
  accessToken: string | undefined
) => {
  const queryFn = ({
                     queryKey,
                     meta,
                     signal,
                   }: QueryFunctionContext<[string]>) => {
    return getProductDataSchemaLibraryColumns.query(
      { queryKey: queryKey, signal: signal, meta: meta },
      accessToken
    );
  };

  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getProductDataSchemaLibraryColumns.name}`],
    queryFn, // Use the adjusted queryFn
    enabled: !!accessToken, // Ensure the query runs only if both segmentId and accessToken are available
    staleTime: Infinity,
  });

  return {
    isLoading,
    isFetching,
    refetch,
    isError,
    data,
  };
};


export const useGetColumnValues = (draftId: number) => {
  const {user} = useAuth();
  const useFetchColumnValues = (colId: string) => {
    const queryFn = ({
                       queryKey,
                       meta,
                       signal,
                     }: QueryFunctionContext<[string]>) => {
      return getColumnValues.query(
        { queryKey: queryKey, signal: signal, meta: meta },
        user?.accessToken ?? undefined,
        draftId,
        colId
      );
    };

    const { isLoading, isError, isFetching, data, refetch } = useQuery({
      queryKey: [`${baseKey}.${getColumnValues.name}`],
      queryFn, // Use the adjusted queryFn
      enabled: !!user?.accessToken && !!colId,
      staleTime: Infinity,
    });

    return {
      isLoading,
      isFetching,
      refetch,
      isError,
      data,
    };
  }

  return useFetchColumnValues;
};
