import React from 'react';
import {
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import useAuth from '../hooks/useAuth'
import { CustomUser, FireBaseRole } from '../context/FirebaseContext';

const useCurrentRole = (user: CustomUser | null) => {
  return user?.roles;
};

interface RoleBasedGuardProps extends React.PropsWithChildren {
  accessibleRoles: FireBaseRole[];
}

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProps) {
  const { user } = useAuth();
  const currentRoles = useCurrentRole(user);

  if (!accessibleRoles.some(r => currentRoles?.includes(r))) {
    return (
      <Center>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Permission Denied</AlertTitle>
          <AlertDescription>You do not have permission to access this page.</AlertDescription>
        </Alert>
      </Center>
    );
  }

  return <>{children}</>;
}
