import z from 'zod';

const branch = z.object({
  id: z.number(),
  branchName: z.string(),
  branchDescription: z.string(),
  isMain: z.boolean(),
  isCompleted: z.boolean(),
  createdBy: z.string().optional(),
  createdDate: z.date().optional(),
  isPublished: z.boolean().optional(),
});
const draftHistoryResponse = z
  .object({
    mainDrafts: z.array(branch).default([]),
    userDrafts: z.array(branch).default([]),
    activeDrafts: z.array(branch).default([]),
    completedDrafts: z.array(branch).default([]),
  })
  .optional();

export type DraftHistoryResponse = z.infer<typeof draftHistoryResponse>;
export type Branch = z.infer<typeof branch>;
