import { Select, CreatableSelect, Size, GroupBase, Props, CreatableProps } from 'chakra-react-select';
import React from 'react';
import { useColorModeValue, useBreakpointValue, Input, forwardRef } from '@chakra-ui/react';

type MultiSelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group>;

export const StyledMultiSelect = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MultiSelectProps<Option, IsMulti, Group>) => {
  const focusColor = useColorModeValue('brand.secondary.light', 'brand.secondary.dark');
  const size = useBreakpointValue<Size>({ base: 'md', md: 'sm' })
  return (
    <Select<Option, IsMulti, Group>
      selectedOptionStyle="check"
      hideSelectedOptions={false}
      focusBorderColor={focusColor}
      selectedOptionColor="orange"
      size={size}

      {...props}
    />
  );
}

type MultiCreatableSelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = CreatableProps<Option, IsMulti, Group>;

export const StyledCreatableSelect = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MultiCreatableSelectProps<Option, IsMulti, Group>) => {
  const focusColor = useColorModeValue('brand.secondary.light', 'brand.secondary.dark');
  const size = useBreakpointValue<Size>({ base: 'md', md: 'sm' })
  return (
    <CreatableSelect<Option, IsMulti, Group>
      selectedOptionStyle="check"
      hideSelectedOptions={false}
      focusBorderColor={focusColor}
      selectedOptionColor="orange"
      size={size}

      {...props}
    />
  );
}

export const StyledInput = forwardRef(function (props, ref) {
  const { ...rest } = props;
  const focusColor = useColorModeValue('brand.secondary.light', 'brand.secondary.dark');
  const size = useBreakpointValue({ base: 'md', md: 'sm' })
  return (
    <Input
      focusBorderColor={focusColor}
      size={size}
      ref={ref}
      {...rest} />
  );
});

/*
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 100 }),
      }}
 */