import React, { useEffect, useRef, useState } from 'react';
import { Link, matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Flex, HStack, useColorModeValue } from '@chakra-ui/react';
import { NavButtonProps, NavItem } from '../../../../@types/BackendViewModels';
import PageNavBarBox from '../../../components/misc/PageNavBarBox';

const NavButton: React.FC<NavButtonProps> = ({ item, disableCompare }) => {
  const { label, to, icon, isDisabled, paths } = item;
  const borderBottomActive = useColorModeValue('blue.500', 'blue.200');
  const borderBottomInactive = useColorModeValue('gray.100', 'gray.900');
  const textColor = useColorModeValue('black', 'white');
  const location = useLocation();

  const matchedPath = React.useCallback((paths: string[]) => paths.some(path => !!matchPath(location.pathname, path)), [location.pathname]);
  const isActive = React.useMemo(() => matchedPath(paths), [matchedPath, paths]);
  return (
    <Box px={0}>
      <Button
        as={Link}
        to={to}
        variant="ghost"
        minW='100px'
        borderRadius={0}
        textColor={textColor}
        fontWeight={isActive ? 'medium' : 'light'}
        fontSize='14px'
        lineHeight='20px'
        letterSpacing='-.1px'
        isDisabled={isDisabled || disableCompare}
        //leftIcon={icon}
        pointerEvents={disableCompare ? "none":"auto"}
        pb={isActive ? '0px' : "0px"}
        h='30px'
        borderBottomWidth={isActive ? '4px' : '0'}
        borderBottomColor={isActive ? borderBottomActive : borderBottomInactive}
        
      >
        {label}
      </Button>
    </Box>
  );
};

interface NavigationProps {
  items: NavItem[];
  disableCompare:boolean;
  children?: React.ReactElement;
}

const NavigationContainer: React.FC<NavigationProps> = ({
  items,
  disableCompare,
  children,
}) => {

  return (
    <PageNavBarBox>
      <>
      {items.map((item, index) => (
        <NavButton key={index} item={item} disableCompare={disableCompare && item.label === "Compare"} />
      ))}
      {children} {/*Top loader */}
      </>
    </PageNavBarBox>
  );
};

export default NavigationContainer;
