import { useContext } from 'react';

import { AccountContext, AccountDispatchContext } from '../context/AccountContext';

// ----------------------------------------------------------------------

const useAccount = () => {
  const context = useContext(AccountContext);

  if (!context) throw new Error('Account context must be used inside AccountProvider');

  return context;
};

export default useAccount;

const useAccountDispatch = () => {
  const context = useContext(AccountDispatchContext);

  if (!context) throw new Error('Account dispatch context must be used inside AccountProvider');

  return context;
};

export {useAccountDispatch};