import { ListManagerProvider, useListManager } from '../../hooks/useListManager';
import useAuth from '../../hooks/useAuth';
import { useCreateBranch, useGetAllBranches, useGetDraftDashboardRecords } from '../../apis/LMBranches';
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import ConfirmationModal from './components/ConfirmationModal';
import CreateBranch from './components/CreateBranch';
import { LinkItem } from '../../../@types/FrontendViewModels';
import {
  FiBook,
  FiCalendar,
  FiClipboard,
  FiClock,
  FiFolder,
  FiGlobe,
  FiHome,
  FiList,
  FiSettings,
} from 'react-icons/fi';
import Layout from '../../components/navigation/Layout';
import ProcessingStatusToast from './components/ProcessingStatusToast';
import { useGetSyncProductCount } from '../../apis/Pricing';

const PricingLayout = () => {
  return (
    <ListManagerProvider>
      <PricingLayoutInner />
    </ListManagerProvider>
  );
}
const PricingLayoutInner = () => {
  const listManagerContext = useListManager();
  const {user} = useAuth();
  const { refetch } = useGetDraftDashboardRecords(user?.accessToken ?? undefined);
  const sidebar = React.useMemo<LinkItem[]>(() => {
    return [
      {
        name: 'Drafts',
        icon: FiHome,
        location: '/pricing',
        isExact: true
      },
      {
        name: 'Current Prices',
        icon: FiClipboard,
        location: `/pricing/b/${listManagerContext.mainBranch?.id}`
      },
      {
        name: 'Schedule',
        icon: FiClock,
        location: '/pricing/schedule',
      },
      {
        name: 'Manage',
        children: [
    /*       {
            name: 'Drafts',
            icon: FiBook,
            location: '/pricing/drafts',
            padding: '8',
          }, */
         /*  {
            name: 'Strategies',
            icon: FiCalendar,
            location: '',
            padding: '8',
            isDisabled: true,
          }, */
          {
            name: 'Formulas',
            icon: FiClipboard,
            location: '/pricing/formulas',
            padding: '8',
          },
         /*  {
            name: 'Segmentation',
            icon: FiList,
            location: '',
            padding: '8',
            isDisabled: true,
          },
          {
            name: 'Global Variables',
            icon: FiGlobe,
            location: '',
            padding: '8',
            isDisabled: true,
          }, */
        ],
      },
      {
        name: 'Data',
        children: [
          {
            name: 'Import/Export',
            icon: FiFolder,
            location: '/pricing/import',
            padding: '8',
          },
          {
            name: 'Properties',
            icon: FiSettings,
            location: '/pricing/property-settings',
            padding: '8',
          },
   /*        {
            name: 'External Connections',
            icon: FiGlobe,
            location: '',
            padding: '8',
            isDisabled: true,
          }, */
        ],
      },
      {
        name: 'Open Drafts',
        children: listManagerContext.activeBranches?.filter(x => !x.isMain).slice(0,5).map(x => {
          return ({
            name: x.branchName,
            location: `/pricing/b/${x.id}`,
            padding: '8'
          })
        })
      },
    ];
  }, [listManagerContext.mainBranch, listManagerContext.activeBranches]);

  return (
      <Box px={0}>
        <Layout sidebarItems={sidebar}>
          <Box px={0}>
            <Outlet />
          </Box>
        </Layout>
        {listManagerContext.syncSystemJobId && (
          <ProcessingStatusToast
            vmName="SyncProductDataVM"
            jobId={listManagerContext.syncSystemJobId}
            callBackFunction={value => {
              listManagerContext.setSyncSystemJobId(value);
            }}
            onCompletedCallback={() => {
              refetch();
            }}
          />
        )}
      </Box>
  );
};

export default PricingLayout;