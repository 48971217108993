import React from 'react';
import { Button, ButtonGroup, VisuallyHidden } from '@chakra-ui/react'
import { GoogleIcon } from './ProviderIcons'

interface OAuthButtonGroupProps {
  signInWithGoogle: () => Promise<void>;
}

const providers = [
  { name: 'Google', icon: <GoogleIcon boxSize="5" /> }
]

export const OAuthButtonGroup = (props: OAuthButtonGroupProps) => (
  <ButtonGroup variant="outline" spacing="4" width="full">
    {providers.map(({ name, icon }) => (
      <Button key={name} width="full" onClick={() => props.signInWithGoogle()}>
        <VisuallyHidden>Sign in with {name}</VisuallyHidden>
        {icon}
      </Button>
    ))}
  </ButtonGroup>
)