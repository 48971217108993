import React, { RefObject } from 'react';
import {
  Box,
  HStack,
  Text,
  IconButton,
  useDisclosure,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Kbd,
  Skeleton,
  Stack,
  Highlight,
  Icon,
  Tooltip,
  Fade,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  FiSearch,
  FiArrowDown,
  FiArrowRight,
  FiAlertTriangle, FiTag,
} from 'react-icons/fi';
import { useAccountDispatch } from '../../hooks/useAccount';
import _ from 'lodash';
import useAuth from '../../hooks/useAuth';
import {
  AdvancedSearchRecord,
  AdvancedSearchResult,
} from '../../../@types/BackendViewModels';
import axios from 'axios';
import { getBadgeColorCustomerStage } from '../../utils/utils';

const AccountAdvancedSearch = () => {
  const accountDispatch = useAccountDispatch();
  const navigate = useNavigate();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const textColor = useColorModeValue('gray.400', 'gray.500');

  const onAccountSelect = (searchRecord: AdvancedSearchRecord) => {
    if (!searchRecord.userHasAccess) {
      navigate('/profile');
      return;
    }
    //TODO maybe get AccountDTO here then set
    accountDispatch.setActiveBranch(searchRecord.branch);
    accountDispatch.setActiveAccount(searchRecord.account);
    onClose();
  };

  const isActive = isHovering || isOpen;

  return (
    <>
      <Popover
        initialFocusRef={initialFocusRef}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-end"
        closeOnBlur
        arrowSize={12}
        isLazy
        closeOnEsc
      >
        <PopoverAnchor>
          <Box
            p={1}
            w={isActive ? '180px' : 10}
            _hover={{
              cursor: 'pointer',
              w: '180px',
              borderColor: borderColor,
            }}
            onMouseEnter={e => setTimeout(() => setIsHovering(true), 500)}
            onMouseLeave={e => setTimeout(() => setIsHovering(false), 500)}
            borderWidth={1}
            borderColor={isActive ? borderColor : 'transparent'}
            borderRadius={6}
            transition={'width 0.5s ease-in-out, border-color 0.5s'}
          >
            <HStack
              justifyContent={'space-between'}
              onClick={onToggle}
              spacing={0}
            >
              {isActive ? (
                <Fade in={isActive}>
                  <Text px={2} fontSize={'xs'} textColor={textColor}>
                    Search...
                  </Text>
                </Fade>
              ) : (
                <Box w={0} h={0} p={0} />
              )}

              <IconButton
                bg={'brand.secondary.light'}
                mx={1}
                aria-label={'search'}
                icon={<FiSearch />}
                borderRadius={3}
                variant={'solid'}
                size={'xs'}
              />
            </HStack>
          </Box>
        </PopoverAnchor>
        <Portal>
          <PopoverContent minW={'600px'}>
            <AdvancedSearchPopover
              initialFocusRef={initialFocusRef}
              onAccountSelect={onAccountSelect}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

const AdvancedSearchPopover = ({
  initialFocusRef,
  onAccountSelect,
}: {
  initialFocusRef: RefObject<HTMLInputElement>;
  onAccountSelect: (searchRecord: AdvancedSearchRecord) => void;
}) => {
  const defaultResult = {
    searchedRecords: [],
    hasMoreResults: false,
    recordsCount: 0,
    isSuccess: false,
  };

  const [inputValue, setInputValue] = React.useState<string>('');
  const [searchResults, setSearchResults] =
    React.useState<AdvancedSearchResult>(defaultResult);

  const [displayedResults, setDisplayedResults] = React.useState<
    AdvancedSearchRecord[]
  >([]);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const { user } = useAuth();
  const linkColor = useColorModeValue('blue.600', 'blue.300');
  const inputFocusColor = useColorModeValue('blue.300', 'blue.600');

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = React.useCallback(
    _.debounce(searchValue => {
      // Call your API or handle the search logic here
      handleSearch(searchValue);
    }, 300),
    []
  ); // 300ms delay

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedOnChange(event.target.value);
  };

  function handleSearch(searchValue: string) {
    if (!searchValue) {
      setSearchResults(defaultResult);
      setDisplayedResults([]);
      setHasSearched(false);
      return;
    }
    setIsSearching(true);
    searchRecords(searchValue);
  }

  const searchRecords = React.useCallback(
    (searchValue: string) => {
      return axios
        .get<AdvancedSearchResult>(
          `${process.env.REACT_APP_URL}/Accounts/Search/${searchValue}`,
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          setSearchResults(res.data);
          setIsSearching(false);
          setHasSearched(true);
          if (res.data.isSuccess) {
            setDisplayedResults(res.data.searchedRecords.slice(0, 5));
          } else {
            setDisplayedResults([]);
          }
        })
        .catch(err => {
          console.error(err);
          setHasSearched(true);
        });
    },
    [user?.accessToken]
  );

  // Handle click to show all results
  const handleShowAll = () => {
    setDisplayedResults(searchResults.searchedRecords); // Show all results
  };

  const isSearchingWaitingResults =
    isSearching && displayedResults.length === 0;
  const noResultsFound =
    hasSearched &&
    !isSearching &&
    inputValue &&
    inputValue.length >= 3 &&
    searchResults.recordsCount == 0;
  const moreResultsFound =
    hasSearched &&
    !isSearching &&
    inputValue &&
    inputValue.length >= 3 &&
    searchResults.recordsCount == 25;
  return (
    <>
      <PopoverArrow />
      <PopoverHeader>
        <FormControl id="search">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="green.500" />
            </InputLeftElement>
            <Input
              placeholder="Search account name or number"
              ref={initialFocusRef}
              onChange={e => handleChange(e)}
              value={inputValue}
              focusBorderColor={inputFocusColor}
            />
          </InputGroup>
        </FormControl>
      </PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody overflowY={'scroll'} maxH={'400px'}>
        {/* Display search results */}
        <HStack>
          <Text fontSize={'x-small'}>Results</Text>
          {searchResults.searchedRecords.length > 0 ? (
            <FiArrowDown strokeWidth={1} />
          ) : (
            <FiArrowRight strokeWidth={1} />
          )}
        </HStack>

        {isSearchingWaitingResults ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : (
          displayedResults.map((result, index) => (
            <AdvancedSearchRecordRow
              key={index}
              result={result}
              onAccountSelect={onAccountSelect}
              searchTerm={inputValue}
            />
          ))
        )}
        {noResultsFound && (
          <Text fontSize={'sm'} p={2} fontWeight={'medium'}>
            <Text as={'span'} fontWeight={'semibold'}>
              No results found.
            </Text>{' '}
            Try modifying your search criteria.
          </Text>
        )}

        {searchResults.searchedRecords.length > 5 &&
        searchResults.searchedRecords.length > displayedResults.length ? (
          <Box p={2}>
            <Text
              fontSize={'xs'}
              fontWeight={'semibold'}
              color={linkColor}
              _hover={{ cursor: 'pointer' }}
              onClick={handleShowAll}
            >{`see all (${searchResults.recordsCount}) results`}</Text>
          </Box>
        ) : (
          <></>
        )}
      </PopoverBody>
      <PopoverFooter display="flex" justifyContent="flex-end">
        <HStack
          justifyContent={'space-between'}
          flexDirection={'row-reverse'}
          w={'full'}
        >
          <Box>
            <Kbd>esc</Kbd>
            <Text as={'span'} fontSize={'xs'} fontWeight={'semibold'}>
              {' '}
              to close
            </Text>
          </Box>
          {moreResultsFound && (
            <Text fontSize={'sm'} p={2} fontWeight={'medium'}>
              <Text as={'span'} fontWeight={'semibold'}>
                Too many results to display.
              </Text>{' '}
              Try narrowing your search criteria.
            </Text>
          )}
        </HStack>
      </PopoverFooter>
    </>
  );
};

const AdvancedSearchRecordRow = ({
  result,
  searchTerm,
  onAccountSelect,
}: {
  result: AdvancedSearchRecord;
  searchTerm: string;
  onAccountSelect: (searchRecord: AdvancedSearchRecord) => void;
}) => {
  const subTextColor = useColorModeValue('gray.700', 'gray.200');
  const highlightColor = useColorModeValue('orange.100', 'orange.700');
  const textColor = useColorModeValue(undefined, 'white');
  const hoverColor = useColorModeValue('gray.200', 'gray.600');

  const content = (
    <Box
      p={2}
      borderRadius={6}
      onClick={() => onAccountSelect(result)}
      _hover={{
        bg: hoverColor,
        cursor: 'pointer',
      }}
    >
      <HStack justifyContent={'space-between'}>
        <HStack spacing={4}>
          {/*result.account.customer_Name === result.account.customer_Parent && <Icon aria-label="This customer is a top parent." as={FiAward} color={'blue.500'} />*/}
          <Box w={12}>
            <Badge
              fontSize={'0.6em'}
              colorScheme={getBadgeColorCustomerStage(
                result.account.customer_Stage
              )}
            >
              {result.account.customer_Stage}
            </Badge>
          </Box>
          {/*<Box w={12}><Badge fontSize={'0.6em'} colorScheme={getColorScheme(result.account.customer_Stage)}>{result.account.customer_Stage}</Badge></Box>*/}
          <Text
            as={'span'}
            fontSize={'xs'}
            fontWeight={'semibold'}
            textColor={subTextColor}
          >
            <Highlight
              query={searchTerm}
              styles={{ py: '1', bg: highlightColor, textColor: subTextColor }}
            >
              {result.account.customer_EID ?? ''}
            </Highlight>
          </Text>
          <Text as={'span'} fontSize={'sm'} fontWeight={'normal'}>
            <Highlight
              query={searchTerm}
              styles={{ py: '1', bg: highlightColor, textColor: textColor }}
            >
              {result.account.customer_Name ?? ''}
            </Highlight>
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Text fontSize={'xs'}>{result.branch.branchKey}</Text>
          {!result.userHasAccess && (
            <Icon
              aria-label="You don't have access to this account."
              as={FiAlertTriangle}
              color={'orange.600'}
            />
          )}
          {result.account.hasFuturePrices && (
            <Icon aria-label="This account has future prices." as={FiTag} h={5} w={5} />
          )}
        </HStack>
      </HStack>
    </Box>
  );

  return result.userHasAccess ? (
    content
  ) : (
    <Tooltip
      placement={'bottom-start'}
      label={`You don't have access to the ${result.branch.branchKey} location. Click the account to request access from your profile page.`}
    >
      {content}
    </Tooltip>
  );
};
export default AccountAdvancedSearch;
