import type { AxiosResponse } from 'axios';
import Axios, { AxiosError } from 'axios';

export const tryFetch = async <T>(request: Promise<AxiosResponse<T>>) => {
  try {
    const result = await request;

    if (!result) {
      const msg = 'Invalid network response';

      console.error(msg);

      return new Error(msg);
    }

    if (result.status === 204) {
      return null;
    }

    return result.data as unknown;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (Axios.isCancel(error)) {
        return null;
      }

      throw error;
    } else if (error instanceof Error) {
      throw error;
    }

    throw new Error('Unkown error');
  }
};
