import React from 'react';
import { HStack, Badge, StackProps, Skeleton, Stack } from '@chakra-ui/react';
import { useListManager } from '../../../hooks/useListManager';
import { DraftDashboardRecordDto, ProductPriceRecord } from '../../../../@types/BackendViewModels';
import { DraftDashboardRecord } from '../../../../@types/FrontendViewModels';
import BranchSelectPopover from './BranchSelectPopover';
import usePricesmithColorMode from '../../../hooks/usePricesmithColorMode';


const BranchSelect = ({
  useBorder = false,
  ...rest
}: { useBorder?: boolean } & StackProps) => {
  const listManagerContext = useListManager();
  const { grayOrWhiteAlphaBorderColor } = usePricesmithColorMode();


  return (
    <HStack
      textAlign={'left'}
      style={
        useBorder
          ? {
              paddingLeft: 15,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: grayOrWhiteAlphaBorderColor,
              borderTop: 0,
              borderRight: 0,
              borderBottom: 0,
            }
          : undefined
      }
      {...rest}
    >
      {listManagerContext.branch ? (
        <>
          <BranchSelectPopover />
        </>
      ) : (
        <Stack style={{ display: 'flex', flexDirection: 'row' }}>
          <Skeleton height="32px" width="98px" rounded={5} />
          <Skeleton height="18px" width="55px" marginTop={'7px'} />
        </Stack>
      )}
    </HStack>
  );
}; 
 export default BranchSelect;
 

/*
      <StyledMultiSelect
        name="branch"
        value={listManagerContext.branch ? { value: listManagerContext.branch.id, label: listManagerContext.branch.branchName } : null}
        options={listManagerContext.branches.map(x => {
          return { value: x.id, label: x.branchName }
        })}
        placeholder="Select branch"
        closeMenuOnSelect={true}
        onChange={(option, valueAsNumber) => {
          console.warn(valueAsNumber);

        }}
      />
            <Select
        variant='outline'
        value={listManagerContext.branch?.id}
        borderRadius={4}
        maxW={'200px'}
        onChange={(e) => {
          listManagerContext.setCurrentBranch(Number(e.target.value).valueOf())
        }}
      >
        {listManagerContext.branches.map(x => <option key={x.id} value={x.id}>{x.branchName}</option>)}
      </Select>
 */
