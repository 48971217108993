import React from 'react';
import Layout from '../../components/navigation/Layout';
import { FiDollarSign, FiGlobe, FiList, FiUser, FiUsers } from 'react-icons/fi';
import { LinkItem } from '../../../@types/FrontendViewModels';
import { ListManagerProvider } from '../../hooks/useListManager';
import AccountContextControls from '../../components/misc/AccountContextControls';
import PageTopElementBox from '../../components/misc/PageTopElementBox';

interface SalesDashboardProps extends React.PropsWithChildren {
  showContext: boolean;
}
const SalesDashboard = ({ showContext, children }: SalesDashboardProps) => {
  return (
    <ListManagerProvider>
      <SalesDashboardInner showContext={showContext}>
        {children}
      </SalesDashboardInner>
    </ListManagerProvider>
  );
};

const SalesDashboardInner = ({
  showContext,
  children,
}: SalesDashboardProps) => {
  const sidebar = React.useMemo<LinkItem[]>(() => {
    return [
      {
        name: 'Account Manager',
        icon: FiDollarSign,
        location: '/sales',
        isExact: true
      },
      {
        name: 'Price Sheets',
        icon: FiList,
        location: '/sales/pricesheets',
      },
      {
        name: 'Manage',
        children: [
          {
            name: 'Customer Prices',
            icon: FiUser,
            location: '',
            padding: '8',
            isDisabled: true
          },
          {
            name: 'Price Agreements',
            icon: FiDollarSign,
            location: '',
            padding: '8',
            isDisabled: true
          },
        ],
      },
      {
        name: 'Reports',
        children: [
          {
            name: 'Discount Trends',
            icon: FiGlobe,
            location: '',
            padding: '8',
            isDisabled: true
          },
          {
            name: 'Price Realization',
            icon: FiDollarSign,
            location: '',
            padding: '8',
            isDisabled: true
          },
        ],
      },
      {
        name: 'Approvals',
        children: [
          {
            name: 'My Requests',
            icon: FiUsers,
            location: '/sales/requests',
            padding: '8',
          },
        ],
      },
    ];
  }, []);

  const topElement = React.useMemo(
    () => (
      <PageTopElementBox>
        <AccountContextControls />
      </PageTopElementBox>
    ),
    []
  );

  return (
    <Layout
      sidebarItems={sidebar}
      topElement={showContext ? topElement : undefined}
    >
      {children}
    </Layout>
  );
};

export default SalesDashboard;
