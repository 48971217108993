import useAuth from '../../hooks/useAuth';
import useAccount, { useAccountDispatch } from '../../hooks/useAccount';
import { Box, Flex, FlexProps, HStack, Icon, Text } from '@chakra-ui/react';
import { FiCheckCircle, FiTag } from 'react-icons/fi';
import { StyledCreatableSelect, StyledMultiSelect } from '../StyledMultiSelect';
import React from 'react';
import {
  chakraComponents,
  OptionProps,
  SingleValueProps,
} from 'chakra-react-select';
import {
  BranchWithOriginalBranch,
  NewAccountWithOriginalAccount,
} from '../../../@types/FrontendViewModels';
import { BranchOrgChart } from '../../../@types/BackendViewModels';
import AccountAdvancedSearch from './AccountAdvancedSearch';

const AccountContextControls = ({ ...rest }: FlexProps) => {
  const { user } = useAuth();
  const accountContext = useAccount();
  const accountDispatch = useAccountDispatch();
  const accounts: NewAccountWithOriginalAccount[] = [];

  let defaultBranchName = '';
  if (accountContext?.branch?.branchKey) {
    defaultBranchName = accountContext?.branch?.branchKey;
  } else if (
    accountContext?.accounts?.length > 0 &&
    accountContext?.accounts[0].branch_Name
  ) {
    defaultBranchName = accountContext?.accounts[0].branch_Name;
  } else if (accountContext?.branches?.length > 0) {
    defaultBranchName = accountContext?.branches[0]?.branchKey;
  }

  const onCreateAccount = (name: string) => {
    const newAccount: NewAccountWithOriginalAccount = {
      value: '',
      label: name,
      icon: null,
      futurePriceIcon: null,
      accountType: null,
      original: {
        customer_Name: name,
        customer_EID: '',
        branch_Name: defaultBranchName,
      },
    };
    accountDispatch.setActiveAccount(newAccount.original);
  };

  accounts.push({
    value: '',
    label: 'New Customer',
    icon: null,
    accountType: null,
    original: {
      customer_Name: 'New Customer',
      customer_EID: '',
      branch_Name: defaultBranchName,
    },
  });
  accountContext.accounts.map(x => {
    accounts.push({
      value: x.customer_IID,
      label: `${x.customer_EID} - ${x.customer_Name}`,
      icon: x.isCommunicatedLast60Days ? (
        <Icon as={FiCheckCircle} h={5} w={5} />
      ) : null,
      futurePriceIcon: x.hasFuturePrices ? (
        <Icon as={FiTag} h={5} w={5} />
      ) : null,
      accountType: x.customer_AccountType_CUR,
      original: x,
    });
  });
  return (
    <Flex w={'full'} flexDirection={{ base: 'column', md: 'row' }} {...rest}>
      <Box
        display={
          user?.has_all_branches || accountContext?.branches?.length > 1
            ? 'block'
            : 'none'
        }
        minW={300}
        py={{ base: 3, md: 0 }}
        px={1}
      >
        <StyledMultiSelect<BranchWithOriginalBranch | null, false>
          isClearable
          name="branch"
          value={
            accountContext.branch
              ? {
                  value: accountContext.branch.branchNum,
                  label: accountContext.branch.branchKey,
                  original: accountContext.branch,
                }
              : null
          }
          options={accountContext.branches.map(x => ({
            value: x.branchNum,
            label: x.branchKey,
            original: x,
          }))}
          placeholder="Select servicing location"
          closeMenuOnSelect={true}
          onChange={option => {
            accountDispatch.setActiveBranch(
              (option as { original?: BranchOrgChart })?.original ?? null
            );
          }}
          components={customBranchComponents}
        />
      </Box>
      <Box minW={350} py={{ base: 3, md: 0 }} px={1}>
        <StyledCreatableSelect<NewAccountWithOriginalAccount | null, false>
          isDisabled={!accountContext.branch}
          isLoading={
            !!accountContext.branch && accountContext.isLoadingAccounts
          }
          isClearable
          name="account"
          onCreateOption={data => onCreateAccount(data)}
          value={
            accountContext.account
              ? {
                  value: accountContext.account.customer_IID,
                  label: accountContext.account.customer_EID
                    ? `${accountContext.account.customer_EID} - ${accountContext.account.customer_Name}`
                    : accountContext.account.customer_Name,
                  icon: accountContext.account.isCommunicatedLast60Days ? (
                    <Icon as={FiCheckCircle} h={5} w={5} />
                  ) : null,
                  futurePriceIcon: accountContext.account.hasFuturePrices ? (
                    <Icon as={FiTag} h={5} w={5} />
                  ) : null,
                  accountType: accountContext.account.customer_AccountType_CUR,
                  original: accountContext.account,
                }
              : null
          }
          options={accounts}
          placeholder="Select account"
          closeMenuOnSelect={true}
          onChange={option => {
            accountDispatch.setActiveAccount(option?.original ?? null);
          }}
          components={customAccountComponents}
        />
      </Box>
      <AccountAdvancedSearch />
    </Flex>
  );
};

const customAccountComponents = {
  Option: ({
    children,
    ...props
  }: OptionProps<NewAccountWithOriginalAccount | null, false>) => (
    <chakraComponents.Option {...props}>
      <HStack justifyContent={'space-between'} w={'full'}>
        <Box p={0}>
          {props.data?.original?.customer_EID ? (
            <HStack spacing={2}>
              <Text
                as={'span'}
                fontSize={{ base: 'sm', md: 'xs' }}
                fontWeight={'semibold'}
              >
                {props.data?.original.customer_EID}
              </Text>
              <Text as={'span'} fontWeight={'normal'}>
                {' '}
                {props.data?.original.customer_Name}
              </Text>
            </HStack>
          ) : (
            <Text
              as={'span'}
              fontSize={{ base: 'sm', md: 'xs' }}
              fontWeight={'semibold'}
            >
              {children}
            </Text>
          )}
        </Box>
        <HStack justifyContent={'flex-end'}>
          <Box>{props.data?.futurePriceIcon}</Box>
          <Box>{props.data?.icon}</Box>
        </HStack>
      </HStack>
    </chakraComponents.Option>
  ),
  SingleValue: ({
    children,
    ...props
  }: SingleValueProps<NewAccountWithOriginalAccount | null, false>) => (
    <chakraComponents.SingleValue {...props}>
      <HStack justifyContent={'space-between'} w={'full'}>
        <Box p={0}>
          {props.data?.original ? (
            <HStack spacing={2}>
              <Text
                as={'span'}
                fontSize={{ base: 'sm', md: 'xs' }}
                fontWeight={'semibold'}
              >
                {props.data?.original?.customer_EID}
              </Text>
              <Text as={'span'} fontWeight={'normal'}>
                {' '}
                {props.data?.original?.customer_Name}
              </Text>
            </HStack>
          ) : (
            <Text
              as={'span'}
              fontSize={{ base: 'sm', md: 'xs' }}
              fontWeight={'semibold'}
            >
              {children}
            </Text>
          )}
        </Box>
        <HStack justifyContent={'flex-end'}>
          <Box>{props.data?.futurePriceIcon}</Box>
          <Box>{props.data?.icon}</Box>
        </HStack>
      </HStack>
    </chakraComponents.SingleValue>
  ),
};

const customBranchComponents = {
  Option: ({
    children,
    ...props
  }: OptionProps<BranchWithOriginalBranch | null, false>) => (
    <chakraComponents.Option {...props}>
      <Box p={0}>
        {props.data?.original ? (
          <HStack spacing={2}>
            <Text
              as={'span'}
              fontSize={{ base: 'sm', md: 'xs' }}
              fontWeight={'semibold'}
            >
              {props.data?.original?.branchNum}
            </Text>
            <Text as={'span'} fontWeight={'normal'}>
              {' '}
              {props.data?.original?.branchKey?.includes('-')
                ? props.data?.original?.branchKey?.split('-')[1].trim()
                : props.data?.original?.branchKey}
            </Text>
          </HStack>
        ) : (
          <Text
            as={'span'}
            fontSize={{ base: 'sm', md: 'xs' }}
            fontWeight={'semibold'}
          >
            {children}
          </Text>
        )}
      </Box>
    </chakraComponents.Option>
  ),
  SingleValue: ({
    children,
    ...props
  }: SingleValueProps<BranchWithOriginalBranch | null, false>) => (
    <chakraComponents.SingleValue {...props}>
      <Box p={0}>
        {props.data?.original ? (
          <HStack spacing={2}>
            <Text
              as={'span'}
              fontSize={{ base: 'sm', md: 'xs' }}
              fontWeight={'semibold'}
            >
              {props.data?.original?.branchNum}
            </Text>
            <Text as={'span'} fontWeight={'normal'}>
              {' '}
              {props.data?.original?.branchKey?.includes('-')
                ? props.data?.original?.branchKey?.split('-')[1].trim()
                : props.data?.original?.branchKey}
            </Text>
          </HStack>
        ) : (
          <Text
            as={'span'}
            fontSize={{ base: 'sm', md: 'xs' }}
            fontWeight={'semibold'}
          >
            {children}
          </Text>
        )}
      </Box>
    </chakraComponents.SingleValue>
  ),
};

export default AccountContextControls;

/*
        <Box p={0}>
        {props.data?.original?.customer_EID ?
          <Text as={'span'} fontSize={'xs'} fontWeight={'semibold'}>{props.data?.original.customer_EID}
            <Text as={'span'} fontSize={'sm'} fontWeight={'normal'}> - {props.data?.original.customer_Name}</Text>
          </Text>
        : <Text as={'span'} fontSize={'xs'} fontWeight={'semibold'}>{children}</Text>}
        </Box>
 */
