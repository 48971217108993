import React from 'react';
import { Text, TextProps, useColorModeValue } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import usePricesmithColorMode from '../../../hooks/usePricesmithColorMode';
// Updated LinkText component
interface LinkTextProps extends LinkProps {
  to: string; // Adding the 'to' prop for React Router Link
}
// Large Title
export const LargeTitle: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="2xl" fontWeight="bold" {...props}>
    {children}
  </Text>
);

// Medium Title
export const MediumTitle: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="xl" fontWeight="bold" {...props}>
    {children}
  </Text>
);

// Small Title
export const SmallTitle: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="lg" fontWeight="600" {...props}>
    {children}
  </Text>
);

// Page Heading
export const PageHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="md" letterSpacing='-0.2px' fontWeight="500" {...props}>
    {children}
  </Text>
);

// Sub Heading
export const SubHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="sm" letterSpacing='-0.2px' fontWeight="500" {...props}>
    {children}
  </Text>
);

// BodyHeading
export const BodyHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="2xl" fontWeight={'light'} as={'abbr'} {...props}>
    {children}
  </Text>
);

// Body
export const BodyText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="md" fontWeight="normal" {...props}>
    {children}
  </Text>
);

// Section Heading
export const SectionHeading: React.FC<TextProps> = ({ children, ...props }) => (
  <Text fontSize="lg" fontWeight="semibold" {...props}>
    {children}
  </Text>
);

// Caption Text
export const DescriptionText: React.FC<TextProps> = ({
  children,
  ...props
}) => (
  <Text fontSize="md" color="gray.600" {...props}>
    {children}
  </Text>
);

// Caption Text
export const CaptionText: React.FC<TextProps> = ({ children, ...props }) => {
  const color = useColorModeValue('gray.600', 'gray.300');
  return (
    <Text fontSize="sm" color={color} {...props}>
      {children}
    </Text>
  );
};

// Caption Text
export const CapsText: React.FC<TextProps> = ({ children, ...props }) => {
  const color = useColorModeValue('gray.800', 'gray.200');
  return (
    <Text
      fontSize="sm"
      color={color}
      fontWeight={'semibold'}
      textTransform="uppercase"
      {...props}
    >
      {children}
    </Text>
  );
};

// Highlighted Text
export const HighlightedText: React.FC<TextProps> = ({
  children,
  ...props
}) => (
  <Text as="span" fontWeight="semibold" color="teal.500" {...props}>
    {children}
  </Text>
);

// Link Text
export const LinkText: React.FC<LinkTextProps> = ({ to, children, ...props }) => {
  const {linkTextColor} = usePricesmithColorMode();
  return   <ChakraLink as={ReactRouterLink} to={to} {...props}>
    <Text
      as="span"
      fontWeight="semibold"
      color={linkTextColor}
      cursor="pointer"
      _hover={{ textDecoration: 'underline' }}
    >
      {children}
    </Text>
  </ChakraLink>
}


// Breadcrumb Text
export const BreadcrumbText: React.FC<TextProps> = ({ children, ...props }) => {
  const breadcrumbColor = useColorModeValue('blue.500', 'blue.200');

  return (
    <Text fontSize="sm" color={breadcrumbColor} fontWeight='medium' {...props}>
      {children}
    </Text>
    )
  };




// Error Text
export const ErrorText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text color="red.500" fontSize="sm" {...props}>
    {children}
  </Text>
);

// Success Text
export const SuccessText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text color="green.500" fontSize="sm" {...props}>
    {children}
  </Text>
);
