import React, { useEffect } from 'react';
import dotnetify, { useConnect } from 'dotnetify';
import useAuth from '../../../hooks/useAuth';
import { ProductDataManagerVM } from '../../../../@types/DotnetifyViewModels';
dotnetify.hubServerUrl = process.env.REACT_APP_URL as string;
import { Box, Text, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useListManager } from '../../../hooks/useListManager';
import {
  useGetItemProperties,
  useGetProductDataWrapperRecords,
} from '../../../apis/ProductDataManager';
import { useGetSchemaLibrary } from '../../../apis/SchemaLibrary/useSchemaLibrary.hooks';

const ProgressToast = ({
  percentage,
  children,
}: {
  percentage: string;
  children: React.ReactNode;
}) => (
  <Box display="flex" alignItems="center" flexDirection={'column'}>
    <Text w="full" m={1}>
      {percentage !== '100'
        ? `${percentage}% is completed.`
        : '100% completed!'}
    </Text>
    {children}
  </Box>
);

const ProcessingStatusToast = ({
  jobId,
  vmName,
  callBackFunction,
  onCompletedCallback
}: {
  jobId: string;
  vmName: string;
  callBackFunction: (value: null) => void;
  onCompletedCallback?: () => void;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const ListManagerContext = useListManager();

  const { vm, state } = useConnect<ProductDataManagerVM>(vmName, undefined, {
    headers: { Authorization: 'Bearer ' + user?.accessToken },
    vmArg: {
      JobId: jobId,
    },
    exceptionHandler: ex => onException(ex),
  });

  useEffect(() => {
    if (jobId) {
      vm?.$dispatch({
        $headers: { Authorization: 'Bearer ' + user?.accessToken },
      });
    }
  }, [vm, user?.accessToken, jobId, state.JobStatus]);

  const onException = (exception: Error) => {
    if (exception.name === 'UnauthorizedAccessException') {
      navigate('/error', { replace: true });
    }
  };

  const { refetch: refetchProducts } = useGetProductDataWrapperRecords(
    ListManagerContext.branch?.id,
    user?.accessToken ?? undefined
  );

  const { SchemaLibraryFetch } = useGetSchemaLibrary(
    'Product',
    user?.accessToken ?? undefined
  );

  const { refetch: RefetchItemProperties } = useGetItemProperties(
    user?.accessToken ?? undefined,
    ListManagerContext.branch?.id ?? 0
  );

  useEffect(() => {
    if (state.JobStatus !== 'Data not available' && state.JobStatus !== null) {
      const existingToast = toast.isActive(`ps${vmName}`);
      if (state.JobStatus && state.ProcessedPercent !== 100) {
        if (existingToast) {
          toast.update(`ps${vmName}`, {
            description: (
              <ProgressToast percentage={state.ProcessedPercent.toFixed()}>
                {vmName === 'SyncProductDataVM' ? (
                  <Text w="full">{`Syncing ${state?.ProcessedRecord?.toFixed()} out of ${
                    state?.TotalRecords
                  } products.`}</Text>
                ) : (
                  <Text w="full">{`Processed Records: ${state?.ProcessedRecord?.toFixed()} / Total Records: ${
                    state?.TotalRecords
                  }`}</Text>
                )}
              </ProgressToast>
            ),
            title:
              vmName === 'SyncProductDataVM'
                ? 'Sync is in progress'
                : 'Import is in progress',
            status: 'loading',
          });
        } else {
          toast({
            id: `ps${vmName}`,
            title:
              vmName === 'SyncProductDataVM'
                ? 'Sync is in progress'
                : 'Import is in progress',
            description: (
              <ProgressToast percentage={state.ProcessedPercent.toFixed()}>
                {vmName === 'SyncProductDataVM' ? (
                  <Text w="full">{`Syncing ${state?.ProcessedRecord?.toFixed()} out of ${
                    state?.TotalRecords
                  } products.`}</Text>
                ) : (
                  <Text w="full">{`Processed Records: ${state?.ProcessedRecord?.toFixed()} / Total Records: ${
                    state?.TotalRecords
                  }`}</Text>
                )}
              </ProgressToast>
            ),
            status: 'loading',
            duration: 500000,
            isClosable: true,
            position: 'bottom-right',
          });
        }
      } else if (state.JobStatus && state.JobStatus.includes('completed')) {
        refetchProducts(); // Refetch product data on product data manager
        SchemaLibraryFetch(); // Refetch Schema Library
        RefetchItemProperties(); // Refetch Item Properties
        if(onCompletedCallback){
          onCompletedCallback();
        }

        if (existingToast) {
          toast.update(`ps${vmName}`, {
            description: (
              <ProgressToast percentage={'100'}>
                {vmName === 'SyncProductDataVM' ? (
                  <Text w="full">{`Synced ${state?.ProcessedRecord?.toFixed()} out of ${
                    state?.TotalRecords
                  } products.`}</Text>
                ) : (
                  <Text w="full">{`Processed Records: ${state?.ProcessedRecord?.toFixed()} / Total Records: ${
                    state?.TotalRecords
                  }`}</Text>
                )}
              </ProgressToast>
            ),
            status: 'success',
            title:
              vmName === 'SyncProductDataVM'
                ? 'Sync completed'
                : 'Import completed',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            id: `ps${vmName}`,
            title:
              vmName === 'SyncProductDataVM'
                ? 'Sync completed'
                : 'Import completed',
            description: (
              <ProgressToast percentage={state.ProcessedPercent.toFixed()}>
                {vmName === 'SyncProductDataVM' ? (
                  <Text w="full">{`Synced ${state?.ProcessedRecord?.toFixed()} out of ${
                    state?.TotalRecords
                  } products.`}</Text>
                ) : (
                  <Text w="full">{`Processed Records: ${state?.ProcessedRecord?.toFixed()} / Total Records: ${
                    state?.TotalRecords
                  }`}</Text>
                )}
              </ProgressToast>
            ),
            status: 'loading',
            duration: 500000,
            isClosable: true,
            position: 'bottom-right',
          });
        }
        setTimeout(() => {
          vm?.$destroy();
          callBackFunction(null);
        }, 2000);
      }
    }
  }, [
    ListManagerContext,
    RefetchItemProperties,
    SchemaLibraryFetch,
    refetchProducts,
    state.JobStatus,
    toast,
    vm,
    state.ProcessedPercent,
    state,
    vmName,
    callBackFunction,
    onCompletedCallback
  ]);

  if (!vm || !state.JobStatus) {
    return <></>;
  }

  return null;
};

export default ProcessingStatusToast;
